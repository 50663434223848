import React, { useState, useEffect } from "react";
import { useProductTypes } from "../../hooks";
import { Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { CostBreakdown } from "../../components/utils/CostBreakdown.jsx";
import Tooltip from "../../../src/components/utils/Tooltip";
import treeDotsIcon from "../../assets/images/treeDotsIconWhite.png";
import XIcon from "../../assets/images/XIcon.svg";
import ShareIcon from "../../assets/images/shareIcon.png";
import PrintIcon from "../../assets/images/printerIcon.png";
import PencilIcon from "../../assets/images/pencilIconWhite.png";
import FertilizerIcon from "../../assets/images/fertilizerIcon3.png";
import CalendarIcon from "../../assets/images/calendarIcon.png";
import BankIcon from "../../assets/images/bankIcon.png";
import ArrowDownIcon from "../../assets/images/ArrowDownIcon.png";
import ShareFormulaDialog from "../screen/FormulasPage/ShareFormulaDialog";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../../redux/actions";
import { useRouteMatch } from "react-router";
import { useTranslation } from "react-i18next";

export default function MyPublicFormulas(
  {
    id,
    name,
    products,
    public: isPublic,
    handleChangeCheckbox,
    checked,
    isToggled,
    deleteMyFormulaUnit,
  },
  publicFormulas = false
) {
  const { t } = useTranslation();
  const productTypes = useProductTypes();
  const [isOpenBreakdown, setIsOpenBreakdown] = useState(false);
  const [isShowingDetails, setIsShowingDetails] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isSecondActionsOpen, setIsSecondActionsOpen] = useState(false);
  const fillers = productTypes.filter((e) => e.isFiller);
  const [isMobile, setIsMobile] = useState();
  const [showDetailsUnitFormula, setShowDetailsUnitFormula] = useState(false);
  const [showDetailsText, setShowDetailsText] = useState(true);
  const [sharing, setSharing] = React.useState(false);
  const dispatch = useDispatch();

  const Checkbox = ({ label, onChange, value, class: className }) => {
    return (
      <label className={className}>
        <input type="checkbox" checked={value} onChange={onChange} />
        <p>{label}</p>
      </label>
    );
  };

  function handleShare() {
    dispatch(Actions.loadFormula(id, name, products, isPublic));
    setSharing(true);
  }

  function deleteMyFormulaUnits() {
    deleteMyFormulaUnit({ id, name });
    setIsOpenModal(false);
  }

  function openModal() {
    setIsOpenModal(true);
  }

  function closeModal() {
    setIsOpenModal(false);
  }

  function closeBreakdown() {
    setIsOpenBreakdown(false);
  }

  function handleDetailsUnitFormula() {
    setShowDetailsUnitFormula(!showDetailsUnitFormula);
    setShowDetailsText(!showDetailsText);
  }

  useEffect(() => {
    if (window.matchMedia("(max-width:900px)")) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);

  return (
    <>
      <ShareFormulaDialog id={id} visible={sharing} setVisible={setSharing} />
      {isToggled ? (
        <div
          className={`formulaMyPublic ${
            publicFormulas && (isPublic ? "" : "borderBottom")
          }`}
        >
          <div className="formulaMyPublic--check">
            <Checkbox
              value={checked}
              id={id}
              onChange={() => handleChangeCheckbox(id)}
            />
          </div>
          <div className="formulaMyPublic--lock">
            {publicFormulas &&
              (isPublic ? null : (
                <svg
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  viewBox="0 0 24 24"
                >
                  <title>Private</title>
                  <path
                    fill="#31c5f4"
                    d="M12 2C9.243 2 7 4.243 7 7v2H6c-1.103 0-2 .897-2 2v9c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-9c0-1.103-.897-2-2-2h-1V7c0-2.757-2.243-5-5-5zM9 7c0-1.654 1.346-3 3-3s3 1.346 3 3v2H9V7zm9.002 13H13v-2.278c.595-.347 1-.985 1-1.722 0-1.103-.897-2-2-2s-2 .897-2 2c0 .736.405 1.375 1 1.722V20H6v-9h12l.002 9z"
                  ></path>
                </svg>
              ))}
          </div>
          <div className="formulaMyPublic--leftSide">
            <img src={FertilizerIcon} alt="" />
          </div>
          <div className="formulaMyPublic--rightSide">
            <h1>{name}</h1>
            <div className="formulaMyPublic--nutri">
              {products.map(({ percentage, type }, index) => (
                <div className="div" key={index}>
                  <Tooltip
                    placement="top"
                    overlay={
                      fillers.find((e) => type === e.id)
                        ? t("Filler")
                        : t("Blend")
                    }
                  >
                    <div
                      className="littleBall"
                      style={{
                        backgroundColor: fillers.find((e) => type === e.id)
                          ? "#9146fa"
                          : "#49b2f5",
                      }}
                    ></div>
                  </Tooltip>
                  <p key={index}>
                    {percentage}% -{" "}
                    <b>
                      {productTypes.find((it) => it.id === type)?.name ||
                        "INVALID"}
                    </b>
                  </p>
                </div>
              ))}
            </div>
            <div className="formulaMyPublic--btns">
              <Tooltip placement="top" overlay={t("Delete Formula")}>
                <button
                  className="btn btnDelete"
                  style={{ height: "40px", width: "40px" }}
                  onClick={() => openModal()}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    viewBox="0 0 24 24"
                  >
                    <path d="M6 7H5v13a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V7H6zm10.618-3L15 2H9L7.382 4H3v2h18V4z"></path>
                  </svg>
                </button>
              </Tooltip>
              {publicFormulas &&
                (isPublic ? null : (
                  <Link
                    className="Link"
                    to={`/my/formula/edit/${id}`}
                    style={{
                      textDecoration: "none",
                      height: "40px",
                      width: "40px",
                    }}
                  >
                    <button className="btn btn--blue center">
                      <img src={PencilIcon} alt="" />
                    </button>
                  </Link>
                ))}
              <button
                className="btn btn--primary center"
                style={{ height: "40px", width: "40px" }}
                onClick={() => handleShare()}
              >
                <img src={ShareIcon} alt="" />
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="myFormulaUnitList">
          {isOpenBreakdown ? (
            <div className="modal">
              <div className="modal--costBreakdown">
                <button
                  className="modalBtn"
                  onClick={
                    () => toast.error("Under Development") /*closeBreakdown()*/
                  }
                >
                  <img src={XIcon} alt="" />
                </button>
                <CostBreakdown />
              </div>
            </div>
          ) : null}
          <div className="myFormulaUnitListHeader">
            <div className="myFormulaUnitListHeaderStart">
              <div className="checkFormulas">
                <Checkbox
                  value={checked}
                  id={id}
                  onChange={() => handleChangeCheckbox(id)}
                />
              </div>
              <div className="nameFormulaUnit">
                <div className="FormulaName">
                  <p className="NameTitle">{name}</p>
                </div>
                <div className="lock">
                  {publicFormulas &&
                    (isPublic ? null : (
                      <svg
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        viewBox="0 0 24 24"
                      >
                        <title>Private</title>
                        <path
                          fill="#31c5f4"
                          d="M12 2C9.243 2 7 4.243 7 7v2H6c-1.103 0-2 .897-2 2v9c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-9c0-1.103-.897-2-2-2h-1V7c0-2.757-2.243-5-5-5zM9 7c0-1.654 1.346-3 3-3s3 1.346 3 3v2H9V7zm9.002 13H13v-2.278c.595-.347 1-.985 1-1.722 0-1.103-.897-2-2-2s-2 .897-2 2c0 .736.405 1.375 1 1.722V20H6v-9h12l.002 9z"
                        ></path>
                      </svg>
                    ))}
                </div>

                {products.map(({ percentage, type }, index) => (
                  <div className="div desktop" key={index}>
                    <Tooltip
                      placement="top"
                      overlay={
                        fillers.find((e) => type === e.id)
                          ? t("Filler")
                          : t("Blend")
                      }
                    >
                      <div
                        className="littleBall"
                        style={{
                          backgroundColor: fillers.find((e) => type === e.id)
                            ? "#9146fa"
                            : "#49b2f5",
                        }}
                      ></div>
                    </Tooltip>
                    <p key={index}>
                      {percentage}% -{" "}
                      <b>
                        {productTypes.find((it) => it.id === type)?.name ||
                          "INVALID"}
                      </b>
                    </p>
                  </div>
                ))}
              </div>
            </div>
            <div className="myFormulaUnitListHeaderEnd">
              <div>
                <button
                  className="showDetailsBtn mobile"
                  onClick={() => handleDetailsUnitFormula()}
                >
                  <p>{showDetailsText ? "Show" : "Hide"} Details</p>
                </button>
                <button
                  className="showDetailsBtn small"
                  onClick={() => handleDetailsUnitFormula()}
                >
                  <img src={ArrowDownIcon} alt="" />
                </button>
                {publicFormulas && !isPublic && (
                  <>
                    <Tooltip placement="top" overlay={t("Delete Formula")}>
                      <button
                        onClick={() => openModal()}
                        style={{ "margin-right": "10px" }}
                        className="btn btnDelete"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="100%"
                          viewBox="0 0 24 24"
                        >
                          <path d="M6 7H5v13a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V7H6zm10.618-3L15 2H9L7.382 4H3v2h18V4z"></path>
                        </svg>
                      </button>
                    </Tooltip>
                    <Tooltip placement="top" overlay={t("Edit Formula")}>
                      <Link
                        to={`/my/formula/edit/${id}`}
                        style={{ textDecoration: "none" }}
                      >
                        <button
                          className="btn btn--blue center"
                          style={{ marginRight: "10px" }}
                        >
                          <img src={PencilIcon} alt="" />
                        </button>
                      </Link>
                    </Tooltip>
                  </>
                )}

                <Tooltip placement="top" overlay={t("Share Formula")}>
                  <button
                    className="btn btn--primary center"
                    onClick={() => handleShare()}
                  >
                    <img src={ShareIcon} alt="" />
                  </button>
                </Tooltip>
              </div>
            </div>
          </div>
          {isShowingDetails
            ? products.map(({ type, percentage }, index) => (
                <li key={index}>
                  {percentage}%{" "}
                  {products.find((it) => it.id === type)?.name || "INVALID"}
                </li>
              ))
            : null}

          <div className="mobileDetails">
            {showDetailsUnitFormula === true
              ? products.map(({ percentage, type }, index) => (
                  <div className="div " key={index}>
                    <Tooltip
                      placement="top"
                      overlay={
                        fillers.find((e) => type === e.id)
                          ? t("Filler")
                          : t("Blend")
                      }
                    >
                      <div
                        className="littleBall"
                        style={{
                          backgroundColor: fillers.find((e) => type === e.id)
                            ? "#9146fa"
                            : "#49b2f5",
                        }}
                      ></div>
                    </Tooltip>
                    <p key={index}>
                      {percentage}% -{" "}
                      <b>
                        {productTypes.find((it) => it.id === type)?.name ||
                          "INVALID"}
                      </b>
                    </p>
                  </div>
                ))
              : null}
          </div>
        </div>
      )}
      {isOpenModal ? (
        <div className="modal">
          <div className="modalDelete">
            <h1>{t("Delete formula?")}</h1>
            <p>
              {t(
                "Are you sure you want to permanently delete the selected formula?"
              )}
            </p>
            <div>
              <button
                className="btn btn--secondary"
                onClick={() => closeModal()}
              >
                Cancel
              </button>
              <button
                className="btn btn--alert"
                onClick={() => deleteMyFormulaUnits({ id, name })}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      ) : null}

      {isOpenBreakdown ? (
        <div className="modal">
          <div className="modal--costBreakdown">
            <button className="modalBtn" onClick={() => closeBreakdown()}>
              <img src={XIcon} alt="" />
            </button>
            <CostBreakdown />
          </div>
        </div>
      ) : null}
    </>
  );
}
