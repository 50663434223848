import React from "react";
import { ApiContext } from "../../../../providers/ApiProvider";
import { NotificationContext } from "../../../../providers/NotificationProvider";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";

import toast from "react-hot-toast";
import ReactLoading from "react-loading";
import XIcon from "../../../../assets/images/XIcon.svg";

import CommonStyles from "../../../CommonStyles.module.scss";
import { Dialog } from "primereact/dialog";
import GenericMaintenanceScreen from "./GenericMaintanceScreen";
import FloatLabel from "../../../utils/FloatLabel";
import useErrorHandler from "../../../../hooks/useErrorHandler";
import { t } from "i18next";

function EditDialog({ id, onHide, visible, notify }) {
  const api = React.useContext(ApiContext);

  const [loading, setLoading] = React.useState(false);
  const [triedOnce, setTriedOnce] = React.useState(false);

  const [code, setCode] = React.useState(id);
  const [name, setName] = React.useState("");

  const handleError = useErrorHandler(
    t("Something went wrong on managing the data")
  );

  React.useEffect(refresh, [id]);

  function refresh() {
    setTriedOnce(false);
    setLoading(true);
    if (id) {
      api
        .getCountry(id)
        .then(({ data }) => {
          setCode(data.alphaCode);
          setName(data.displayName);
          setLoading(false);
        })
        .catch(handleError);
    } else {
      setCode("");
      setName("");
      setLoading(false);
    }
  }

  function send() {
    setTriedOnce(true);
    if (name) {
      const payload = { name };
      setLoading(true);
      if (id) {
        api.editCountry(id, payload).then(finish).catch(handleError);
      } else {
        api
          .addCountry({ code, ...payload })
          .then(finish)
          .catch(handleError);
      }
    }
  }

  function finish() {
    toast.success(t("Country saved successfully"), name);
    onHide();
    notify();
  }

  function deletedToast() {
    toast.success(t("Country deleted successfully"), name);
    onHide();
    notify();
  }

  function handleDelete({ id, t }) {
    api.deleteCountry(id).then(deletedToast).catch(handleError);
    toast.dismiss(t.id);
  }

  function askDelete() {
    toast((_t) => (
      <div className="deleteToastAdm">
        <h1>
          {t("Are you sure you want to delete")}{" "}
          <b>
            {name} ({id})
          </b>
          ?
        </h1>
        <p>{t("This is a one-way action!")}</p>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button
            className="btn btn--secondary"
            onClick={() => toast.dismiss(_t.id)}
          >
            {t("Cancel")}
          </button>
          <button
            style={{ marginLeft: "5px" }}
            className="btn btn--alert center"
            onClick={() => handleDelete({ id, _t })}
          >
            {t("Delete")}
          </button>
        </div>
      </div>
    ));
  }

  const header = (
    <>
      <div style={{ position: "relative" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h1 style={{ margin: "0" }}>
            {id ? `${t("Editing")} ${name} (${id})` : t("Creating")}
          </h1>
          <button className="xIconButton center" onClick={onHide}>
            <img src={XIcon} alt="" />
          </button>
        </div>
        <p>
          {id
            ? t(`Change the name or delete the country`)
            : t(
                "Write the Alpha Code and the country Name to create a new one"
              )}
        </p>
      </div>
    </>
  );

  const body = (
    <>
      <div className="cardsWrapperAdm">
        {!id && (
          <div className="firstFloatLabel">
            <FloatLabel
              className="firstFloatLabel"
              id="alphaCode"
              label={t("Alpha Code (2 or 3 digits)")}
            >
              <InputText
                maxLength={3}
                value={code}
                onChange={({ target }) => setCode(target.value.toUpperCase())}
              ></InputText>
            </FloatLabel>
          </div>
        )}
        <FloatLabel id="name" label={t("Name")}>
          <InputText
            value={name}
            onChange={({ target }) => setName(target.value)}
          />
        </FloatLabel>
        {triedOnce && !name && (
          <span className={CommonStyles.RedText}>
            *{t("pleaseProvideName")}
          </span>
        )}
      </div>
    </>
  );

  const footer = (
    <>
      {id && !loading ? (
        <>
          <div style={{ width: "100%", display: "flex" }}>
            <button className="btn btn--alert center" onClick={askDelete}>
              {t("Delete")}
            </button>
            <button className="btn btn--primary" onClick={send}>
              {t("Save")}
            </button>
          </div>
        </>
      ) : (
        <>
          {!loading && (
            <button className="btn btn--primary" onClick={send}>
              {t("Create")}
            </button>
          )}
        </>
      )}
    </>
  );

  return (
    <Dialog
      className="modalAdmWrapper"
      onHide={onHide}
      visible={visible}
      header={header}
      footer={footer}
      draggable={false}
    >
      {loading ? (
        <div className="dfCenter">
          <ReactLoading
            type={"spinningBubbles"}
            color={"#31c5f4"}
            height={"40px"}
            width={"40px"}
          />
        </div>
      ) : (
        body
      )}
    </Dialog>
  );
}

export default function CountryMaintenance() {
  const api = React.useContext(ApiContext);

  const dialog = ({ id, onHide, visible, notify }) => (
    <EditDialog id={id} onHide={onHide} visible={visible} notify={notify} />
  );

  function filterData(data, filterTable) {
    const dataFiltered =
      filterTable.length > 0
        ? data.filter(
            (e) =>
              (e.id &&
                e.id
                  .toLowerCase()
                  .replace(/[\s-]/g, "")
                  .includes(filterTable.replace(/[\s-]/g, "").toLowerCase())) ||
              (e.displayName &&
                e.displayName
                  .toLowerCase()
                  .replace(/[\s-]/g, "")
                  .includes(filterTable.replace(/[\s-]/g, "").toLowerCase()))
          )
        : data;

    return dataFiltered;
  }

  const provider = async () => {
    const countries = (await api.getCountries()).data;
    return {
      data: countries.map(({ alphaCode, displayName }) => ({
        id: alphaCode,
        displayName,
      })),
    };
  };

  return (
    <GenericMaintenanceScreen
      dataProvider={provider}
      renderEditDialog={dialog}
      filterData={filterData}
    >
      <Column field="id" header="Alpha Code" sortable />
      <Column field="displayName" header="Name" sortable />
    </GenericMaintenanceScreen>
  );
}
