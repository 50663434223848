import React, { useState, useEffect } from "react";
import XIcon from "../../assets/images/XIcon.svg";
import FilterIcon from "../../assets/images/FilterIcon.png";
import { useSelector } from "react-redux";
import { orGet } from "../../calculation/results-utils";
import Tooltip from "../../../src/components/utils/Tooltip";
import { t } from "i18next";

export const Category = {
  CIF: 0,
  Export: 1,
  DeliveredToBlendingFactory: 2,
  DeliveredAtImporterWarehouse: 3,
  ExImporterWarehouse: 4,
  DeliveredAtWholesalerWarehouse: 5,
  FinalPrices: 6,
};

export function CostBreakdown({
  data,
  filter,
  headers,
  headersTitle,
  showDetails,
  styles,
  dropdownSelectedPOE,
  dropdownSelectedPOP,
  dropDownSelectedDestination,
  setTotalPriceAtDestination,
}) {
  const [isShowing, setIsShowing] = useState(false);
  const [checkedCIF, setCheckedCIF] = useState(true);
  const [checkedPricesExPort, setCheckedPricesExPort] = useState(true);
  const [checkedPriceBlendingFactory, setCheckedPriceBlendingFactory] =
    useState(true);
  const [checkedPriceImporterWarehouse, setCheckedPriceImporterWarehouse] =
    useState(true);
  const [checkedPriceExImporterWarehouse, setCheckedPriceExImporterWarehouse] =
    useState(true);
  const [checkedPriceDeliveredWarehouse, setCheckedPriceDeliveredWarehouse] =
    useState(true);
  const [checkedFinalPrices, setCheckedFinalPrices] = useState(true);
  const [buttonDetails, setButtonDetails] = useState(true);
  const aux1 = useSelector((state) => state);

  const handleChangeCIF = () => {
    setCheckedCIF(!checkedCIF);
  };

  const handleChangePricesExPort = () => {
    setCheckedPricesExPort(!checkedPricesExPort);
  };

  const handleChangePriceBlendingFactory = () => {
    setCheckedPriceBlendingFactory(!checkedPriceBlendingFactory);
  };

  const handleChangePriceImporterWarehouse = () => {
    setCheckedPriceImporterWarehouse(!checkedPriceImporterWarehouse);
  };

  const handleChangePriceExImporterWarehouse = () => {
    setCheckedPriceExImporterWarehouse(!checkedPriceExImporterWarehouse);
  };

  const handleChangePriceDeliveredWarehouse = () => {
    setCheckedPriceDeliveredWarehouse(!checkedPriceDeliveredWarehouse);
  };

  const handleChangeFinalPrices = () => {
    setCheckedFinalPrices(!checkedFinalPrices);
  };

  const [openModal, setOpenModal] = useState(false);

  const Checkbox = ({ label, value, onChange }) => {
    return (
      <label>
        <input type="checkbox" checked={value} onChange={onChange} />
        {label}
      </label>
    );
  };

  function handleOpenModal() {
    setOpenModal(!openModal);
    setButtonDetails(!buttonDetails);
  }

  useEffect(() => {
    const aux = extractValue(
      (it) => orGet(it.wholesalePricesProductionArea) / 20
    );
    setTotalPriceAtDestination && setTotalPriceAtDestination(aux);
  }, []);

  /* Dinamic Functions */

  function CategoryRenderer({ category, children }) {
    return filter.length === 0 || filter.some((it) => it === category)
      ? children
      : null;
  }

  const currency = useSelector((state) => state.currency.currency);
  const fcfa = useSelector((state) => state.currency.fcfa);
  const ghs = useSelector((state) => state.currency.ghs);
  const ngn = useSelector((state) => state.currency.ngn);

  function extractValue(selector) {
    function select(it) {
      const selected = selector(it);

      if (selected === "") return 0;
      else if (
        selected === undefined ||
        (isNaN(selected) && typeof selected !== "string")
      ) {
        return undefined;
      } else {
        const value = selected.toLocaleString(undefined, {
          maximumFractionDigits: 0,
        });
        const formatValue = value.replace(".", " ");
        return typeof selected === "number" ? formatValue : selected;
      }
    }
    return (
      data &&
      data.map((it, index) => {
        const selectedValue = select(it);
        if (selectedValue === undefined) {
          return undefined;
        } else {
          return select(it);
        }
      })
    );
  }

  function details(selectValue) {
    return !openModal ? null : extractValue(selectValue);
  }

  return (
    <>
      <div className="relative">
        {isShowing ? (
          <div className="resultsFilter">
            <div className="labelFilter">
              <Checkbox
                label="CIF Price at Destination"
                value={checkedCIF}
                onChange={handleChangeCIF}
              />
            </div>
            <div className="labelFilter">
              <Checkbox
                label="Prices Ex-Port"
                value={checkedPricesExPort}
                onChange={handleChangePricesExPort}
              />
            </div>

            <div className="labelFilter">
              <Checkbox
                label="Price delivered to the blending factory"
                value={checkedPriceBlendingFactory}
                onChange={handleChangePriceBlendingFactory}
              />
            </div>

            <div className="labelFilter">
              <Checkbox
                label="Price delivered at the importer warehouse"
                value={checkedPriceImporterWarehouse}
                onChange={handleChangePriceImporterWarehouse}
              />
            </div>
            <div className="labelFilter">
              <Checkbox
                label="Prices ex importer warehouse"
                value={checkedPriceExImporterWarehouse}
                onChange={handleChangePriceExImporterWarehouse}
              />
            </div>
            <div className="labelFilter">
              <Checkbox
                label="Price delivered at wholesaler warehouse"
                value={checkedPriceDeliveredWarehouse}
                onChange={handleChangePriceDeliveredWarehouse}
              />
            </div>
            <div className="labelFilter">
              <Checkbox
                label="Final Prices"
                value={checkedFinalPrices}
                onChange={handleChangeFinalPrices}
              />
            </div>
            <button onClick={() => setIsShowing(!isShowing)}>
              <img src={XIcon} alt="X" />
            </button>
          </div>
        ) : null}
        <div className="Results">
          {(dropdownSelectedPOE && !dropdownSelectedPOE.name) ||
          (dropdownSelectedPOP && !dropdownSelectedPOP.name) ||
          (dropDownSelectedDestination && !dropDownSelectedDestination.town) ? (
            <>
              <p
                style={{
                  fontSize: "18px",
                  marginBottom: "15px",
                  color: "#697384",
                }}
              >
                Please, select your preferences to view the respective cost
                prices.
              </p>
              <p
                style={{
                  fontSize: "16px",
                  marginBottom: "15px",
                  color: "#31c5f4",
                }}
              >
                {dropdownSelectedPOE.name
                  ? // dropdownSelectedPOP.name ?
                    dropDownSelectedDestination.town
                    ? ""
                    : "Select a arrival city"
                  : // : 'Select a Place of Bagging or Blending'
                    "Select a departure city"}
              </p>
            </>
          ) : (
            <>
              <div className="resultsHeader">
                <h1>Cost Breakdown:</h1>
                <Tooltip overlay={t("Filter Costs")}>
                  <button onClick={() => setIsShowing(!isShowing)}>
                    <img src={FilterIcon} alt="" />
                  </button>
                </Tooltip>
              </div>
              <div className="resultsMain">
                <p>{t("AllValuesInUSD")}</p>
                <div className="resultsUnit">
                  <p>{t("Departure City")}</p>
                  {dropdownSelectedPOE && dropdownSelectedPOE.name && (
                    <span>
                      {dropdownSelectedPOE.name +
                        ` (${dropdownSelectedPOE.country.alphaCode})`}
                    </span>
                  )}
                </div>
                {/* <div className="resultsUnit">
                                    <p>Place of Bagging or Blending</p>
                                    {dropdownSelectedPOP && dropdownSelectedPOP.name && <span>{dropdownSelectedPOP.name + ` (${dropdownSelectedPOP.country.alphaCode})`}</span>}
                                </div> */}
                <div className="resultsUnit">
                  <p>{t("Arrival City")}</p>
                  {dropDownSelectedDestination &&
                    dropDownSelectedDestination.town && (
                      <span>
                        {dropDownSelectedDestination.town.name +
                          ` (${dropDownSelectedDestination.town.country.alphaCode})`}
                      </span>
                    )}
                </div>
                {checkedCIF && (
                  <>
                    <div className="resultsUnit resultsUnitBold">
                      <p>FOB Price at Origin</p>
                      <span>
                        {extractValue((it) => orGet(it.fobPriceAtOrigin))}
                      </span>
                    </div>
                    <div className="resultsDetailsWrapper">
                      <div className="resultsUnit">
                        <p>Freight</p>
                        <span>
                          {extractValue(
                            (it) =>
                              orGet(it.seaFreight) +
                              orGet(it.seaFreightInsurrance)
                          )}
                        </span>
                      </div>
                      {openModal && (
                        <div className="resultsDetails">
                          <div className="resultsUnitsDetails">
                            <p>Freight Charge</p>
                            <span>
                              {details((it) => orGet(it.seaFreight)) === ""
                                ? "-"
                                : details((it) => orGet(it.seaFreight))}
                            </span>
                          </div>
                          <div className="resultsUnitsDetails">
                            <p>Insurrance</p>
                            <span>
                              {details((it) => orGet(it.seaFreightInsurrance))}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="resultsUnit resultsUnitBold">
                      <p>CIF price at Destination</p>
                      <span>
                        {extractValue((it) => orGet(it.cifPriceDestination))}
                      </span>
                    </div>
                  </>
                )}
                {checkedPricesExPort && (
                  <>
                    <div className="resultsDetailsWrapper">
                      <div className="resultsUnit">
                        <p>Port Charges</p>
                        <span>
                          {extractValue(
                            (it) =>
                              orGet(it.chargesAndFeesAtPort) +
                              orGet(it.portForwarderFees) +
                              orGet(it.demurrage)
                          )}
                        </span>
                      </div>
                      {openModal && (
                        <div className="resultsDetails">
                          <div className="resultsUnitsDetails">
                            <p>Charges and Fees at Port</p>
                            <span>
                              {details((it) => orGet(it.chargesAndFeesAtPort))}
                            </span>
                          </div>
                          <div className="resultsUnitsDetails">
                            <p>Forwarder Fees (port)</p>
                            <span>
                              {details((it) => orGet(it.portForwarderFees))}
                            </span>
                          </div>
                          <div className="resultsUnitsDetails">
                            <p>Demurrage</p>
                            <span>{details((it) => orGet(it.demurrage))}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="resultsDetailsWrapper">
                      <div className="resultsUnit">
                        <p>Custom Charges</p>
                        <span>
                          {extractValue((it) => {
                            return (
                              orGet(it.customLevy) +
                              orGet(it.ecowasAu) +
                              orGet(it.statisticLevy) +
                              orGet(it.solidarityLevy) +
                              orGet(it.ccvrLevy) +
                              orGet(it.eximLevy)
                            );
                          })}
                        </span>
                      </div>
                      {openModal && (
                        <div className="resultsDetails">
                          <div className="resultsUnitsDetails">
                            <p>Custom Levy</p>
                            <span>
                              {" "}
                              {details((it) => orGet(it.customLevy))}
                            </span>
                          </div>
                          <div className="resultsUnitsDetails">
                            <p>ECOWAS + Afrian Union</p>
                            <span> {details((it) => orGet(it.ecowasAu))}</span>
                          </div>
                          <div className="resultsUnitsDetails">
                            <p>Statistics Levy</p>
                            <span>
                              {" "}
                              {details((it) => orGet(it.statisticLevy))}
                            </span>
                          </div>
                          <div className="resultsUnitsDetails">
                            <p>Solidarity Levy</p>
                            <span>
                              {details((it) => orGet(it.solidarityLevy))}
                            </span>
                          </div>
                          <div className="resultsUnitsDetails">
                            <p>CCVR Levy</p>
                            <span>{details((it) => orGet(it.ccvrLevy))}</span>
                          </div>
                          <div className="resultsUnitsDetails">
                            <p>Exim Levy</p>
                            <span>{details((it) => orGet(it.eximLevy))}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="resultsUnit resultsUnitBold">
                      <p>Prices Ex-port</p>
                      <span>
                        {extractValue((it) => orGet(it.pricesExport))}
                      </span>
                    </div>
                  </>
                )}
                {checkedPriceBlendingFactory && (
                  <>
                    <div className="resultsDetailsWrapper">
                      <div className="resultsUnit">
                        <p>Direct Transit</p>
                        <span>
                          {extractValue(
                            (it) =>
                              orGet(it.garantyFound) +
                              orGet(it.transportToInland) +
                              orGet(it.otherSmallTransitFees)
                          )}
                        </span>
                      </div>
                      {openModal && (
                        <div className="resultsDetails">
                          <div className="resultsUnitsDetails">
                            <p>Garanty Found (FGR)</p>
                            <span>
                              {details((it) => orGet(it.garantyFound))}
                            </span>
                          </div>
                          <div className="resultsUnitsDetails">
                            <p>Transport to Inland</p>
                            <span>
                              {details((it) => orGet(it.transportToInland))}
                            </span>
                          </div>
                          <div className="resultsUnitsDetails">
                            <p>Other Small Transit Fees</p>
                            <span>
                              {details((it) => orGet(it.otherSmallTransitFees))}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="resultsUnit">
                      <p>Port to Warehouse</p>
                      <span>
                        {extractValue((it) => orGet(it.portToWarehouse))}
                      </span>
                    </div>
                    <div className="resultsDetailsWrapper">
                      <div className="resultsUnit resultsUnitBold">
                        <p>Price Delivered to the blending factory</p>
                        <span>
                          {extractValue((it) =>
                            orGet(it.priceDeliveredToBlendingFactory)
                          )}
                        </span>
                      </div>
                      {openModal && (
                        <div className="resultsDetails">
                          <div className="resultsUnitsDetails">
                            <p>Cost Ingredients</p>
                            <span>
                              {details((it) => orGet(it.costIngredients))}
                            </span>
                          </div>
                          <div className="resultsUnitsDetails">
                            <p>Cost Process</p>
                            <span>
                              {details((it) => orGet(it.costProccess))}
                            </span>
                          </div>
                          <div className="resultsUnitsDetails">
                            <p>Factory Depreciation</p>
                            <span>
                              {details((it) => orGet(it.factoryDepreciation))}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                )}
                {checkedPriceImporterWarehouse && (
                  <div className="resultsUnit resultsUnitBold">
                    <p>Price Ex-Work (FOT)</p>
                    <span>
                      {extractValue((it) =>
                        orGet(it.priceDeliveredAtImporterWarehouse)
                      )}
                    </span>
                  </div>
                )}
                {checkedPriceExImporterWarehouse && (
                  <>
                    <div className="resultsDetailsWrapper">
                      <div className="resultsUnit">
                        <p>Overheads Importer</p>
                        <span>
                          {extractValue((it) => {
                            return (
                              orGet(it.importerBankFees) +
                              orGet(it.importerBankInterest) +
                              orGet(it.importerStorageFees) +
                              orGet(it.importerHandlingFees) +
                              orGet(it.importerAdministrativeFees) +
                              orGet(it.importerInsurrance) +
                              orGet(it.importerLossOfWeight) +
                              orGet(it.importerRawMargin) +
                              orGet(it.importerTaxesOnMargin)
                            );
                          })}
                        </span>
                      </div>
                      {openModal && (
                        <div className="resultsDetails">
                          <div className="resultsUnitsDetails">
                            <p>Bank Fees</p>
                            <span>
                              {details((it) => orGet(it.importerBankFees))}
                            </span>
                          </div>
                          <div className="resultsUnitsDetails">
                            <p>Bank Interest</p>
                            <span>
                              {details((it) => orGet(it.importerBankInterest))}
                            </span>
                          </div>
                          <div className="resultsUnitsDetails">
                            <p>Storage Fees</p>
                            <span>
                              {details((it) => orGet(it.importerStorageFees))}
                            </span>
                          </div>
                          <div className="resultsUnitsDetails">
                            <p>Handling Fees</p>
                            <span>
                              {details((it) => orGet(it.importerHandlingFees))}
                            </span>
                          </div>
                          <div className="resultsUnitsDetails">
                            <p>Administrative Fees</p>
                            <span>
                              {details((it) =>
                                orGet(it.importerAdministrativeFees)
                              )}
                            </span>
                          </div>
                          <div className="resultsUnitsDetails">
                            <p>Insurrance</p>
                            <span>
                              {details((it) => orGet(it.importerInsurrance))}
                            </span>
                          </div>
                          <div className="resultsUnitsDetails">
                            <p>Loss of Weight</p>
                            <span>
                              {details((it) => orGet(it.importerLossOfWeight))}
                            </span>
                          </div>
                          <div className="resultsUnitsDetails">
                            <p>Raw Margin</p>
                            <span>
                              {details((it) => orGet(it.importerRawMargin))}
                            </span>
                          </div>
                          <div className="resultsUnitsDetails">
                            <p>Taxes on Margin</p>
                            <span>
                              {details((it) => orGet(it.importerTaxesOnMargin))}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="resultsUnit resultsUnitBold">
                      <p>Prices Ex-Importer warehouse</p>
                      <span>
                        {extractValue((it) =>
                          orGet(it.pricesExImportWarehouse)
                        )}
                      </span>
                    </div>
                  </>
                )}
                {checkedPriceDeliveredWarehouse && (
                  <>
                    <div className="resultsDetailsWrapper">
                      <div className="resultsUnit resultsUnitBold">
                        <p>Transport to Production Area</p>
                        <span>
                          {extractValue(
                            (it) =>
                              orGet(it.transport) +
                              orGet(it.customLandBorder) +
                              orGet(it.forwardingLandBorder)
                          )}
                        </span>
                      </div>
                      {openModal == true ? (
                        <div className="resultsDetails">
                          <div className="resultsUnitsDetails">
                            <p>Transport</p>
                            <span>{details((it) => orGet(it.transport))}</span>
                          </div>
                          <div className="resultsUnitsDetails">
                            <p>Custom Land Border</p>
                            <span>
                              {details((it) => orGet(it.customLandBorder))}
                            </span>
                          </div>
                          <div className="resultsUnitsDetails">
                            <p>Forwarding Land Border</p>
                            <span>
                              {details((it) => orGet(it.forwardingLandBorder))}
                            </span>
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className="resultsUnit resultsUnitBold">
                      <p>Price Delivered at the wholesaler warehouse</p>
                      <span>
                        {extractValue((it) =>
                          orGet(it.priceDeliveredAtWholesalerWarehouse)
                        )}
                      </span>
                    </div>
                  </>
                )}
                {checkedFinalPrices && (
                  <>
                    <div className="resultsDetailsWrapper">
                      {openModal && (
                        <div className="resultsDetails">
                          <div className="resultsUnitsDetails">
                            <p>Storage Fees</p>
                            <span>
                              {details((it) => orGet(it.wholesalerStorageFees))}
                            </span>
                          </div>
                          <div className="resultsUnitsDetails">
                            <p>Handling Fees</p>
                            <span>
                              {details((it) =>
                                orGet(it.wholesalerHandlingFees)
                              )}
                            </span>
                          </div>
                          <div className="resultsUnitsDetails">
                            <p>Loss of Weight</p>
                            <span>
                              {details((it) =>
                                orGet(it.wholesalerLossOfWeight)
                              )}
                            </span>
                          </div>
                          <div className="resultsUnitsDetails">
                            <p>Raw Margin</p>
                            <span>
                              {details((it) => orGet(it.wholesalerRawMargin))}
                            </span>
                          </div>
                          <div className="resultsUnitsDetails">
                            <p>Taxes on Margin</p>
                            <span>
                              {details((it) =>
                                orGet(it.wholesalerTaxesOnMargin)
                              )}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="resultsUnit resultsUnitBold resultUnitsGreen">
                      <p>Wholesale prices production area</p>
                      <span>
                        {extractValue((it) =>
                          orGet(it.wholesalePricesProductionArea)
                        )}
                      </span>
                    </div>
                    {currency === "USD" && (
                      <div
                        className="resultsUnit resultUnitsGreen"
                        style={{ fontWeight: "bold" }}
                      >
                        <p>Wholesale prices production area (USD/50kg bag)</p>
                        <span>
                          {extractValue(
                            (it) => orGet(it.wholesalePricesProductionArea) / 20
                          )}
                        </span>
                      </div>
                    )}
                    {currency === "FCFA" && (
                      <div
                        className="resultsUnit resultUnitsGreen"
                        style={{ fontWeight: "bold" }}
                      >
                        <p>Wholesale prices production area (FCFA/50kg bag)</p>
                        <span>
                          {extractValue(
                            (it) =>
                              orGet(it.wholesalePricesProductionArea) *
                              (fcfa / 20)
                          )}
                        </span>
                      </div>
                    )}
                    {currency === "GHS" && (
                      <div
                        className="resultsUnit resultUnitsGreen"
                        style={{ fontWeight: "bold" }}
                      >
                        <p>Wholesale prices production area (GH₵/50kg bag)</p>
                        <span>
                          {extractValue(
                            (it) =>
                              orGet(it.wholesalePricesProductionArea) *
                              (ghs / 20)
                          )}
                        </span>
                      </div>
                    )}
                    {currency === "NGN" && (
                      <div
                        className="resultsUnit resultUnitsGreen"
                        style={{ fontWeight: "bold" }}
                      >
                        <p>Wholesale prices production area (NGN/50kg bag)</p>
                        <span>
                          {extractValue(
                            (it) =>
                              orGet(it.wholesalePricesProductionArea) *
                              (ngn / 20)
                          )}
                        </span>
                      </div>
                    )}
                  </>
                )}
              </div>
            </>
          )}
        </div>
        {dropdownSelectedPOE &&
          dropdownSelectedPOE.name &&
          dropdownSelectedPOP &&
          dropdownSelectedPOP.name &&
          dropDownSelectedDestination &&
          dropDownSelectedDestination.town && (
            <button onClick={handleOpenModal} className="btn btn--secondary">
              {buttonDetails ? t("More Details") : t("Hide Details")}
            </button>
          )}
      </div>
    </>
  );
}
