import React from "react";
import { ApiContext } from "../../../../providers/ApiProvider";
import { NotificationContext } from "../../../../providers/NotificationProvider";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";

import toast from "react-hot-toast";
import ReactLoading from "react-loading";
import XIcon from "../../../../assets/images/XIcon.svg";
import useErrorHandler from "../../../../hooks/useErrorHandler";

import CommonStyles from "../../../CommonStyles.module.scss";
import { Dialog } from "primereact/dialog";
import GenericMaintenanceScreen from "./GenericMaintanceScreen";
import FloatLabel from "../../../utils/FloatLabel";
import { Checkbox } from "primereact/checkbox";
import { TabPanel, TabView } from "primereact/tabview";
import { t } from "i18next";

function EditDialog({ id, onHide, visible, notify }) {
  const api = React.useContext(ApiContext);
  const notification = React.useContext(NotificationContext);
  const handleError = useErrorHandler(
    t("Something went wrong on managing the data")
  );

  const [loading, setLoading] = React.useState(false);
  const [triedOnce, setTriedOnce] = React.useState(false);

  const [name, setName] = React.useState("");
  const [minFob, setMinFob] = React.useState(0);
  const [maxFob, setMaxFob] = React.useState(0);
  const [hs6Code, setHs6Code] = React.useState(0);
  const [customTarrif, setCustomTarrif] = React.useState(0);

  const [isFiller, setIsFiller] = React.useState(false);
  const [N, setN] = React.useState(0);
  const [P, setP] = React.useState(0);
  const [K, setK] = React.useState(0);
  const [Ca, setCa] = React.useState(0);
  const [Mg, setMg] = React.useState(0);
  const [S, setS] = React.useState(0);
  const [Zn, setZn] = React.useState(0);
  const [B, setB] = React.useState(0);
  const [Cu, setCu] = React.useState(0);
  const [Cl, setCl] = React.useState(0);

  React.useEffect(refresh, [id]);

  function refresh() {
    setTriedOnce(false);
    setLoading(true);
    if (id) {
      api
        .getProductType(id)
        .then(({ data }) => {
          setName(data.name);
          setMinFob(data.minFob);
          setMaxFob(data.maxFob);
          setHs6Code(data.hs6Code ? data.hs6Code : 0);
          setCustomTarrif(data.customTarrif ? data.customTarrif : 0);

          setIsFiller(data.isFiller);
          setN(data.N);
          setP(data.P2O5);
          setK(data.K2O);
          setCa(data.Ca);
          setMg(data.Mg);
          setS(data.S);
          setZn(data.Zn);
          setB(data.B);
          setCu(data.Cu);
          setCl(data.Cl);
          setLoading(false);
        })
        .catch((e) => {
          console.error(e);
          notification.sendError(
            t("Something went wrong on fetching the data"),
            e.message
          );
        });
    } else {
      setName("");
      setMinFob(0);
      setMaxFob(0);
      setHs6Code(0);
      setCustomTarrif(0);
      setIsFiller(false);
      setN(0);
      setP(0);
      setK(0);
      setCa(0);
      setMg(0);
      setS(0);
      setZn(0);
      setB(0);
      setCu(0);
      setCl(0);
      setLoading(false);
    }
  }

  function send() {
    setTriedOnce(true);

    function isNumber(it) {
      return typeof it === "number";
    }

    if (name && [minFob, maxFob, hs6Code, customTarrif].every(isNumber)) {
      const payload = {
        name,
        minFob,
        maxFob,
        hs6Code,
        customTarrif,
        isFiller,
        N,
        Ca,
        Mg,
        S,
        Zn,
        B,
        Cu,
        Cl,
        P2O5: P,
        K2O: K,
      };
      setLoading(true);
      if (id) {
        api
          .editProductType(id, payload)
          .then(finish)
          .catch((e) => {
            console.error(e);
            notification.sendError(
              t("Something went wrong on saving the data"),
              e.message
            );
          });
      } else {
        api
          .addProductType(payload)
          .then(finish)
          .catch((e) => {
            console.error(e);
            notification.sendError(
              t("Something went wrong on saving the data"),
              e.message
            );
          });
      }
      setLoading(false);
    }
  }

  function finish() {
    toast.success(t("Product saved successfully"), name);
    onHide();
    notify();
  }

  function deletedToast() {
    toast.success(t("Product deleted successfully"), name);
    onHide();
    notify();
  }

  function handleDelete({ id, t }) {
    api.deleteProductType(id).then(deletedToast).catch(handleError);
    toast.dismiss(t.id);
  }

  function askDelete() {
    toast((_t) => (
      <div className="deleteToastAdm">
        <h1>
          {" "}
          {t("Are you sure you want to delete")} <b>{name}</b>?
        </h1>
        <p>{t("This is an one-way action!")}</p>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button
            className="btn btn--secondary"
            onClick={() => toast.dismiss(_t.id)}
          >
            {t("Cancel")}
          </button>
          <button
            style={{ marginLeft: "5px" }}
            className="btn btn--alert center"
            onClick={() => handleDelete({ id, _t })}
          >
            {t("Delete")}
          </button>
        </div>
      </div>
    ));
  }

  const header = (
    <>
      <div style={{ position: "relative" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h1 style={{ margin: "0" }}>
            {id ? `${t("Editing")} ${name}` : t("Creating")}
          </h1>
          <button className="xIconButton center" onClick={onHide}>
            <img src={XIcon} alt="" />
          </button>
        </div>
        <p>
          {id
            ? t(`Select the options below to edit the Product`)
            : t("Select the options below to create the Product")}
        </p>
      </div>
    </>
  );

  const body = (
    <>
      <TabView>
        <TabPanel
          style={{ padding: "0 1.5rem 0rem 1.5rem !important" }}
          header={t("Details")}
        >
          <div className="firstFloatLabel32">
            <FloatLabel id="name" label="Name">
              <InputText
                value={name}
                onChange={({ target }) => setName(target.value)}
              />
            </FloatLabel>
          </div>
          {triedOnce && !name && (
            <span className={CommonStyles.RedText}>
              {t("Please provide a name")}
            </span>
          )}
          <div className="firstFloatLabel32">
            <FloatLabel id="minFob" label="Min FOB Price">
              <InputNumber
                value={minFob}
                mode="currency"
                currency="USD"
                onChange={({ value }) => setMinFob(value)}
              />
            </FloatLabel>
          </div>
          <div className="firstFloatLabel32">
            <FloatLabel id="maxFob" label="Max FOB Price">
              <InputNumber
                value={maxFob}
                mode="currency"
                currency="USD"
                onChange={({ value }) => setMaxFob(value)}
              />
            </FloatLabel>
          </div>
          <div className="firstFloatLabel32">
            <FloatLabel id="hs6Code" label="HS6 Code">
              <InputNumber
                value={hs6Code}
                useGrouping={false}
                min={0}
                onChange={({ value }) => setHs6Code(value)}
              />
            </FloatLabel>
          </div>

          <FloatLabel id="customTarrif" label="Custom Tarrif">
            <InputNumber
              value={customTarrif}
              suffix="%"
              min={-1}
              onChange={({ value }) => setCustomTarrif(value)}
            />
          </FloatLabel>
        </TabPanel>
        <TabPanel header="Nutrients">
          <div className="p-grid">
            <div className="p-col-12 p-field">
              <label>
                <Checkbox
                  checked={isFiller}
                  onChange={(e) => setIsFiller(e.checked)}
                  style={{ marginRight: 4 }}
                />
                Is Filler
              </label>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div className="p-col-6" style={{ textAlign: "center" }}>
                {[
                  ["N", N, setN],
                  ["P", P, setP],
                  ["K", K, setK],
                  ["Ca", Ca, setCa],
                  ["Mg", Mg, setMg],
                ].map(([label, value, setValue]) => (
                  <div className="p-inputgroup">
                    <label htmlFor={label} className="p-inputgroup-addon">
                      {label}
                    </label>
                    <InputNumber
                      inputId={label}
                      value={value}
                      mode="decimal"
                      maxFractionDigits={2}
                      minFractionDigits={2}
                      min={0}
                      onValueChange={(e) => setValue(e.value)}
                    />
                  </div>
                ))}
              </div>
              <div className="p-col-6" style={{ textAlign: "center" }}>
                {[
                  ["S", S, setS],
                  ["Zn", Zn, setZn],
                  ["B", B, setB],
                  ["Cu", Cu, setCu],
                  ["Cl", Cl, setCl],
                ].map(([label, value, setValue]) => (
                  <div className="p-inputgroup">
                    <label htmlFor={label} className="p-inputgroup-addon">
                      {label}
                    </label>
                    <InputNumber
                      inputId={label}
                      value={value}
                      mode="decimal"
                      maxFractionDigits={2}
                      minFractionDigits={2}
                      min={0}
                      onValueChange={(e) => setValue(e.value)}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </TabPanel>
      </TabView>
    </>
  );

  const footer = (
    <>
      {id && !loading ? (
        <>
          <div style={{ width: "100%", display: "flex" }}>
            <button className="btn btn--alert center" onClick={askDelete}>
              Delete
            </button>
            <button className="btn btn--primary" onClick={send}>
              Save
            </button>
          </div>
        </>
      ) : (
        <>
          {!loading && (
            <button className="btn btn--primary" onClick={send}>
              Create
            </button>
          )}
        </>
      )}
    </>
  );

  return (
    <Dialog
      className="modalAdmWrapper"
      onHide={onHide}
      visible={visible}
      header={header}
      footer={footer}
      draggable={false}
    >
      {loading ? (
        <div className="dfCenter">
          <ReactLoading
            type={"spinningBubbles"}
            color={"#31c5f4"}
            height={"40px"}
            width={"40px"}
          />
        </div>
      ) : (
        body
      )}
    </Dialog>
  );
}

export default function ProductTypeMaintenance() {
  const api = React.useContext(ApiContext);

  const dialog = ({ id, onHide, visible, notify }) => (
    <EditDialog id={id} onHide={onHide} visible={visible} notify={notify} />
  );

  function filterData(data, filterTable) {
    const dataFiltered =
      filterTable.length > 0
        ? data.filter(
            (e) =>
              e.name &&
              e.name
                .toLowerCase()
                .replace(/[\s-]/g, "")
                .includes(filterTable.replace(/[\s-]/g, "").toLowerCase())
          )
        : data;

    return dataFiltered;
  }

  return (
    <GenericMaintenanceScreen
      dataProvider={api.getProductTypes}
      renderEditDialog={dialog}
      filterData={filterData}
    >
      <Column field="id" header="Id" sortable />
      <Column field="name" header="Name" sortable />
      <Column field="minFob" header="Min Fob" sortable />
      <Column field="maxFob" header="Max Fob" sortable />
      <Column field="hs6Code" header="HS6 Code" sortable />
      <Column field="customTarrif" header="Custom Tarrif" sortable />
    </GenericMaintenanceScreen>
  );
}
