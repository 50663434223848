import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import firebase from "firebase/app";
import Styles from "./index.module.scss";
import toast, { Toaster } from "react-hot-toast";
import SelectDropdown from "../../../utils/SelectDropdown";
import {
  usePorts,
  usePlacesOfBlending,
  useFinalDestinations,
  useProductTypes,
  useIsAdmin,
} from "../../../../hooks";
import ListOfIngredients from "./ListOfIngredients";
import IdeaImg from "../../../../../src/assets/images/ideaImg.svg";
import AirplaneImg from "../../../../../src/assets/images/AirplanesImg.png";
import XIcon from "../../../../../src/assets/images/XIcon.svg";
import Science from "../../../../../src/assets/images/science.svg";
import InfoIcon from "../../../../../src/assets/images/infoIcon.svg";
import Formula from "../../../utils/Formula";
import * as Actions from "../../../../redux/actions";
import { DragDropContext } from "react-beautiful-dnd";
import { auth } from "../../../../services/firebase";
import {
  byId,
  queryRemoteFormulas,
} from "../../../../hooks/queryRemoteFormulas";
import { useDeepCompareMemoized } from "../../../../utils";
import EditProductDialog from "../EditProductDialog";
import { CostBreakdown } from "../../../utils/CostBreakdown.jsx";
import ReactLoading from "react-loading";
import ShareFormulaDialog from "../../FormulasPage/ShareFormulaDialog";
import ShareIcon from "../../../../../src/assets/images/shareIcon.png";
import Unlock from "../../../../../src/assets/images/unlock.png";
import Lock from "../../../../../src/assets/images/lock.png";
import Cookies from "universal-cookie";
import { optimizePercentages } from "nutrient-optimization";
import SelectDropdownMobile from "../../../utils/SelectDropdownMobile";
import Tooltip from "rc-tooltip";
import doesRouteExist from "../../../utils/functions/doesRouteExist.js";
import useLandTransports from "../../../../hooks/useLandTransports.js";
import { t } from "i18next";

const cookies = new Cookies();

export function FormulaCreation(props) {
  const dispatch = useDispatch();
  const firestore = firebase.firestore();
  const [N, setN] = useState(0);
  const [P, setP] = useState(0);
  const [K, setK] = useState(0);
  const [Ca, setCa] = useState(0);
  const [Mg, setMg] = useState(0);
  const [S, setS] = useState(0);
  const [Zn, setZn] = useState(0);
  const [B, setB] = useState(0);
  const [Cu, setCu] = useState(0);
  const [Cl, setCl] = useState(0);
  const [showFormulas, setShowFormulas] = useState(3);
  const [suggestedFormulas, setSuggestedFormulas] = useState([
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ]);
  const [dropdownSelectedPOE, setDropdownSelectedPOE] = useState(
    cookies.get("POE") === undefined ? "" : cookies.get("POE")
  );
  const [dropdownSelectedPOP, setDropdownSelectedPOP] = useState(
    cookies.get("POP") === undefined ? "" : cookies.get("POP")
  );
  const [dropDownSelectedDestination, setDropdownSelectedDestination] =
    useState(cookies.get("FD") === undefined ? "" : cookies.get("FD"));
  const [isPlacesClicked, setIsPlacesClicked] = useState(false);
  const [isNutrientsClicked, setIsNutrientsClicked] = useState(false);
  const [formulas, setFormulas] = useState([]);
  const [isFormulaShowing, setIsFormulaShowing] = useState(
    props.match.params.formulaId ? true : false
  );
  const [isListIngredientOn, setIsListIngredientsOn] = useState(false);
  const [isResultsOn, setIsResultsOn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sharing, setSharing] = React.useState(false);
  const [isHoveringNutrients, setIsHoveringNutrients] = useState(false);
  const [buttonStart, setButtonStart] = useState(
    props?.match?.params?.formulaId ? t("Recalculate") : t("Start")
  );
  const [checkAutoAddFiller, setCheckAutoAddFiller] = useState(true);
  const formulaRedux = useSelector((state) => state.formula);
  const selectedFormula = props?.match?.params?.formulaId;
  const formulaData = queryRemoteFormulas([byId(selectedFormula)]);
  const productTypes = useProductTypes();
  const isAdmin = useIsAdmin();
  const isPublicFormula = useSelector((state) => state.formula.public);

  useEffect(() => {
    if (formulaData) {
      const { id, name, products, public: isPublic } = formulaData;
      dispatch(Actions.loadFormula(id, name, products, isPublic));
    }
  }, [useDeepCompareMemoized(formulaData)]);

  useEffect(() => {
    if (cookies.get("POE") === undefined) {
      alert(
        "This system uses cookies! We temporarily store data to improve your browsing experience. By using our services, you consent to such monitoring."
      );
    }
    async function fetchData() {
      setIsLoading(true);
      if (props?.match?.params?.formulaId) {
        const myFormula = await firestore
          .collection("formulas")
          .where("email", "==", auth.currentUser?.email)
          .get();

        const formulasMy = myFormula.docs.map((it) => {
          if (it.id === props.match.params.formulaId) {
            return { ...it.data() };
          }
        });
        const formula = formulasMy.filter((e) => e !== undefined)[0];
        setFormulas([
          {
            ...formula,
            id: props?.match?.params?.formulaId,
          },
        ]);
      }
      setIsLoading(false);
    }
    fetchData();
  }, []);

  const [landTransports] = useLandTransports();

  const handleChangeDropdown = (value, setValue, myId) => {
    if (myId) {
      let routeExists = true;

      if (myId === "searchbarPOE") {
        cookies.set("POE", value, {
          path: "/",
          expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365),
        });
        routeExists = doesRouteExist(
          value,
          dropDownSelectedDestination,
          landTransports
        );
      }

      // else if (myId === "searchbarPOP")
      //   cookies.set("POP", value, {
      //     path: "/",
      //     expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365),
      //   });
      else {
        cookies.set("FD", value, {
          path: "/",
          expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365),
        });
        routeExists = doesRouteExist(
          dropdownSelectedPOE,
          value,
          landTransports
        );
      }
      if (!routeExists) {
        toast((t) => (
          <div className="route-missing-toast">
            <h1>The selected route is not available.</h1>
            <p>
              You can proceed, but the absence of this route will affect the
              calculation results. Please contact an administrator to create the
              route.
            </p>

            <button
              className="btn btn--secondary"
              onClick={() => toast.dismiss(t.id)}
            >
              Confirm
            </button>
          </div>
        ));
      }
    }
    setValue(value);
  };

  const handleChangeDropdownMobile = (value, setValue, myId) => {
    setValue(value);
  };

  const setValuesNutrientsFilter = (e, setStates) => {
    let value = checkValue(e.target);
    if (isNaN(value)) value = 0;
    setStates(value);
  };

  function checkValue(sender) {
    let min = sender.min;
    let max = sender.max;
    let value = parseFloat(sender.value);

    if (value > max) {
      toast.error(t("The max value for the input is 100"));
      return (sender.value = max);
    }
    if (value < min) {
      toast.error(t("The min value for the input is 0"));
      return (sender.value = "");
    }

    return value;
  }

  const ports = usePorts();
  var selectListPOE = ports[0]
    .map((port) => {
      return port.name + ` (${port.country.alphaCode})`;
    })
    .sort();

  const fillers = productTypes.filter((e) => e.isFiller);

  const placesOfBlending = usePlacesOfBlending();
  var selectListPOP = placesOfBlending[0]
    .map((place) => {
      return place.name + ` (${place.country.alphaCode})`;
    })
    .sort();

  const finalDestinations = useFinalDestinations();
  var selectListFD = finalDestinations[(0, 0)]
    .map((destination) => {
      return destination.town.name + ` (${destination.town.country.alphaCode})`;
    })
    .sort();

  const CheckboxAutoAddFiller = () => {
    return (
      <label className="checkbox">
        <input
          type={"checkbox"}
          checked={checkAutoAddFiller}
          onChange={() => setCheckAutoAddFiller(!checkAutoAddFiller)}
        />
        <span style={{ fontSize: "12px", color: "#444", marginLeft: "5px" }}>
          {t("Auto add filler")}
        </span>
      </label>
    );
  };

  async function checkStart() {
    const sumNutrients = N + P + K + Ca + Mg + S + Zn + B + Cu + Cl;

    if (
      dropdownSelectedPOE === "" &&
      dropdownSelectedPOP === "" &&
      dropDownSelectedDestination === ""
    ) {
      toast(t("Select the dropdowns below"), {
        icon: "⚠️",
      });
    } else if (dropdownSelectedPOE === "") {
      toast.error(t("Select a Departure City"), {
        icon: "⚠️",
      });
      // } else if (dropdownSelectedPOP == "") {
      //   toast.error(t("Select a Place of Processing"), {
      //     icon: "⚠️",
      //   });
    } else if (dropDownSelectedDestination === "") {
      toast.error(t("Select an Arrival City"), {
        icon: "⚠️",
      });
    } else if (sumNutrients > 100) {
      toast.error(t("The sum of the nutrients must be 100 or less"), {
        icon: "⚠️",
      });
    } else if (sumNutrients <= 0) {
      toast.error(t("The sum of the nutrients must be more than 0"), {
        icon: "⚠️",
      });
    } else {
      setIsLoading(true);
      setButtonStart(
        props?.match?.params?.formulaId ? "Recalculate" : "Restart"
      );
      setShowFormulas(3);
      setIsFormulaShowing(true);
      setIsNutrientsClicked(false);

      const filteredNutrients = productTypes.filter((e) => {
        var keys = [
          "id",
          "name",
          "minFob",
          "maxFob",
          "isFiller",
          "N",
          "phosphorus",
          "potassium",
          "Ca",
          "Mg",
          "S",
          "Zn",
          "B",
          "Cu",
          "Cl",
          "hs6Code",
          "customTarrif",
        ];

        const objsValues = Object.values(e);

        var e = objsValues.reduce(function (e, field, index) {
          e[keys[index]] = field;
          return e;
        }, {});

        /* This logic with the flagReproveIngredient and the array reproveIngredient was 
				created not to add an ingredient in the formula that has a value of 
				NPK, Zn or B in its composition, but it was not typed in the filter. It must be discarded. */

        let flagReproveIngredient = 0;

        const reproveIngredient = [
          e.N > 0 && N == 0 ? true : false,
          e.phosphorus > 0 && P == 0 ? true : false,
          e.potassium > 0 && K == 0 ? true : false,
          e.Zn > 0 && Zn == 0 ? true : false,
          e.B > 0 && B == 0 ? true : false,
        ];

        reproveIngredient.map((bool) => {
          if (bool === true) flagReproveIngredient = 1;
        });

        if (e.N > 0 && N > 0 && flagReproveIngredient == 0) {
          return true;
        }

        if (e.phosphorus > 0 && P > 0 && flagReproveIngredient == 0) {
          return true;
        }

        if (e.potassium > 0 && K > 0 && flagReproveIngredient == 0) {
          return true;
        }

        if (e.Ca > 0 && Ca > 0 && flagReproveIngredient == 0) {
          return true;
        }

        if (e.Mg > 0 && Mg > 0 && flagReproveIngredient == 0) {
          return true;
        }

        if (e.S > 0 && S > 0 && flagReproveIngredient == 0) {
          return true;
        }

        if (e.Zn > 0 && Zn > 0 && flagReproveIngredient == 0) {
          return true;
        }

        if (e.B > 0 && B > 0 && flagReproveIngredient == 0) {
          return true;
        }

        if (e.Cu > 0 && Cu > 0 && flagReproveIngredient == 0) {
          return true;
        }

        if (e.Cl > 0 && Cl > 0 && flagReproveIngredient == 0) {
          return true;
        }
      });

      // Logic of Suggested formulas

      // Here, we go to get only the keys of nutrients and the name of him, because we want the nutrients values of an ingredient, so we discard the keys that will not going to be used

      filteredNutrients.map((nutrient) => {
        var productsValues = [
          "name",
          "N",
          "phosphorus",
          "potassium",
          "Ca",
          "Mg",
          "S",
          "Zn",
          "B",
          "Cu",
          "Cl",
        ];

        const objsValues = Object.values(nutrient);

        let count = 0;
        var keys = [
          "id",
          "name",
          "minFob",
          "maxFob",
          "isFiller",
          "N",
          "phosphorus",
          "potassium",
          "Ca",
          "Mg",
          "S",
          "Zn",
          "B",
          "Cu",
          "Cl",
          "hs6Code",
          "customTarrif",
        ];

        var keysValues = objsValues.reduce(function (e, value, index) {
          if (
            keys[index] !== "id" &&
            keys[index] !== "minFob" &&
            keys[index] !== "maxFob" &&
            keys[index] !== "isFiller" &&
            keys[index] !== "hs6Code" &&
            keys[index] !== "customTarrif"
          ) {
            e[productsValues[count]] = value;
            count++;
          }
          return e;
        }, {});

        // After getting the values, we have to take the highest nutrient value of an ingredient
        const sort = Object.values(keysValues);
        const keysFilter = Object.keys(keysValues);

        sort.sort(function (a, b) {
          return b - a;
        });

        var getValueNutrient = keysFilter.reduce(function (e, value, index) {
          // sort [1] is the highest value
          if (keysValues[value] === sort[1]) {
            e = value;
          }
          return e;
        }, {});

        // Push the ingredients that have the nutrients typed for the user

        switch (getValueNutrient) {
          case "N":
            if (N > 0) suggestedFormulas[0].push(nutrient);
            break;

          case "phosphorus":
            if (P > 0) suggestedFormulas[1].push(nutrient);
            break;

          case "potassium":
            if (K > 0) suggestedFormulas[2].push(nutrient);
            break;

          case "Ca":
            if (Ca > 0) suggestedFormulas[3].push(nutrient);
            break;

          case "Mg":
            if (Mg > 0) suggestedFormulas[4].push(nutrient);
            break;

          case "S":
            if (S > 0) suggestedFormulas[5].push(nutrient);
            break;

          case "Zn":
            if (Zn > 0) suggestedFormulas[6].push(nutrient);
            break;

          case "B":
            if (B > 0) suggestedFormulas[7].push(nutrient);
            break;

          case "Cu":
            if (Cu > 0) suggestedFormulas[8].push(nutrient);
            break;

          case "Cl":
            if (Cl > 0) suggestedFormulas[9].push(nutrient);
            break;
        }
      });

      const suggestedFormulasList = [];

      let flag = 0;

      let onlyOneIngredient = [];

      // Loop to do all the combinations of formulas
      // When the suggestedFormulasList will be empty, the loop is ended

      while (flag != 1) {
        // Checking if have combinations to do
        const filterList = suggestedFormulas.filter((e) => e.length > 1);

        let pushIngredient = [];

        /* The array onlyOneIngredient was create, because in some cases, the nutrients typed by user, 
					will bring to us only one ingredient to do combinations
				so we have to granted that this ingredient will be used in all formulas, pushing them on the array */

        for (let i = 0; i < suggestedFormulas.length; i++) {
          if (suggestedFormulas[i].length > 0) {
            for (let j = 0; j < suggestedFormulas[i].length; j++) {
              if (suggestedFormulas[i][j] != []) {
                if (suggestedFormulas[i].length === 1) {
                  onlyOneIngredient.push(suggestedFormulas[i][j]);
                  suggestedFormulas[i].shift(suggestedFormulas[i][j]);
                } else if (suggestedFormulas[i].length > 1) {
                  pushIngredient.push(suggestedFormulas[i][j]);
                  suggestedFormulas[i].shift(suggestedFormulas[i][j]);
                }
                j = suggestedFormulas[i].length;
              }
            }
          }
        }

        // Pushing the ingredient to the combinations created
        onlyOneIngredient.map((e) => pushIngredient.push(e));

        let loop = fillers.length;
        if (props?.match?.params?.formulaId) {
          flag = 1;
          loop = 1;
        }

        // Adding the fillers that we have in database
        // The formula will be repeated, but with different fillers

        if (checkAutoAddFiller) {
          for (let cont = 0; cont < loop; cont++) {
            pushIngredient.push(fillers[cont]);
            const formulaProducts = getFormulas(pushIngredient);

            const formula = {
              orderFormula: suggestedFormulasList.length + 1,
              id: suggestedFormulasList.length + 1,
              name: `Formula ${suggestedFormulasList.length + 1}`,
              products: formulaProducts.map((product) => {
                return {
                  ...product,
                  ...productTypes.find(
                    (element) => element.id === product.type
                  ),
                  color: product.isFiller ? "#9146fa" : "#49b2f5",
                  bankInterestRate: 0,
                  demurrage: 0,
                  demurrageDuration: 0,
                  demurragePrice: 0,
                  loanCashoutDuration: 0,
                  passageMode: 1,
                  placeOfBlending: 1,
                  seaFreight: 0,
                  storageCost: 0,
                  storageDuration: 0,
                  transit: "NONE",
                  port:
                    dropdownSelectedPOE && dropdownSelectedPOE.id
                      ? dropdownSelectedPOE.id
                      : null,
                };
              }),
              totalPrice: "",
            };

            suggestedFormulasList.push(formula);
            const removeFiller = pushIngredient.filter(
              (e) => e.id !== fillers[cont].id
            );
            pushIngredient = removeFiller;
          }
        } else {
          const formulaProducts = getFormulas(pushIngredient);
          const formula = {
            orderFormula: suggestedFormulasList.length + 1,
            id: suggestedFormulasList.length + 1,
            name: `Formula ${suggestedFormulasList.length + 1}`,
            products: formulaProducts.map((product) => {
              return {
                ...product,
                ...productTypes.find((element) => element.id === product.type),
                color: product.isFiller ? "#9146fa" : "#49b2f5",
                bankInterestRate: 0,
                demurrage: 0,
                demurrageDuration: 0,
                demurragePrice: 0,
                loanCashoutDuration: 0,
                passageMode: 1,
                placeOfBlending: 1,
                seaFreight: 0,
                storageCost: 0,
                storageDuration: 0,
                transit: "NONE",
                port:
                  dropdownSelectedPOE && dropdownSelectedPOE.id
                    ? dropdownSelectedPOE.id
                    : null,
              };
            }),
            totalPrice: "",
          };
          suggestedFormulasList.push(formula);
        }
        if (filterList.length === 0) {
          flag = 1;
        }
      }
      const filteredFormulas = suggestedFormulasList.filter((formula) => {
        const totalPercentage = formula.products.reduce((sum, product) => {
          return sum + parseFloat(product.percentage);
        }, 0);

        return totalPercentage <= 100;
      });

      setFormulas(filteredFormulas);
      setSuggestedFormulas([[], [], [], [], [], [], [], [], [], []]);
      setIsLoading(false);
    }
  }

  function discardFormulas(formula) {
    const filteredFormulas = formulas.filter((e) => e.id !== formula.id);
    setFormulas(filteredFormulas);
    toast.success(formula.name + " was discarded");

    if (filteredFormulas.length === 0) {
      setIsFormulaShowing(false);
    }
  }

  function addProductFormula(formula, ingredient) {
    const filteredFormula = formulas.find((e) => e.name === formula);

    const getIngredient = productTypes.find((e) => e.name === ingredient);

    let suggestedFormulasList = [];
    let formulaProducts = [];
    const formulasList = formulas.filter((e) => e.name !== formula);

    if (props?.match?.params?.formulaId) {
      if (getIngredient?.isFiller) {
        const hasFiller = filteredFormula.products.filter((element) => {
          if (element.isFiller) {
            element.type = getIngredient.id;
            return element;
          }
        });

        if (hasFiller.length > 0) {
          suggestedFormulasList = [
            {
              orderFormula: filteredFormula.orderFormula,
              id: filteredFormula.id,
              name: formula,
              products: [...filteredFormula.products],
              totalPrice: "",
            },
            ...formulasList,
          ];
        } else {
          // Calculating the percentages and adding the fillers to complete 100% in the formula
          let filler = 0;
          let percentage = 0;

          for (let i = 0; i < filteredFormula.products.length; i++) {
            filler =
              filler + parseFloat(filteredFormula.products[i].percentage);
          }

          filler = 100 - filler;

          if (filler > 0) percentage = filler;

          suggestedFormulasList = [
            {
              orderFormula: filteredFormula.orderFormula,
              id: filteredFormula.id,
              name: formula,
              products: [
                ...filteredFormula.products.map((product) => {
                  return {
                    ...product,
                    ...productTypes.find(
                      (element) => element.id === product.type
                    ),
                    color: product.isFiller ? "#9146fa" : "#49b2f5",
                    bankInterestRate: 0,
                    demurrage: 0,
                    demurrageDuration: 0,
                    demurragePrice: 0,
                    loanCashoutDuration: 0,
                    passageMode: 1,
                    placeOfBlending: 1,
                    seaFreight: 0,
                    storageCost: 0,
                    storageDuration: 0,
                    transit: "NONE",
                    port:
                      dropdownSelectedPOE && dropdownSelectedPOE.id
                        ? dropdownSelectedPOE.id
                        : null,
                  };
                }),
                {
                  type: getIngredient.id,
                  percentage: percentage,
                  ...getIngredient,
                },
              ],
              totalPrice: "",
            },
            ...formulasList,
          ];
        }
      } else {
        suggestedFormulasList = [
          {
            orderFormula: filteredFormula.orderFormula,
            id: filteredFormula.id,
            name: formula,
            products: [
              ...filteredFormula.products.map((product) => {
                return {
                  ...product,
                  ...productTypes.find(
                    (element) => element.id === product.type
                  ),
                  color: product.isFiller ? "#9146fa" : "#49b2f5",
                  bankInterestRate: 0,
                  demurrage: 0,
                  demurrageDuration: 0,
                  demurragePrice: 0,
                  loanCashoutDuration: 0,
                  passageMode: 1,
                  placeOfBlending: 1,
                  seaFreight: 0,
                  storageCost: 0,
                  storageDuration: 0,
                  transit: "NONE",
                  port:
                    dropdownSelectedPOE && dropdownSelectedPOE.id
                      ? dropdownSelectedPOE.id
                      : null,
                };
              }),
              { type: getIngredient.id, percentage: 0, ...getIngredient },
            ],
            totalPrice: "",
          },
          ...formulasList,
        ];
      }
    } else {
      if (getIngredient?.isFiller) {
        filteredFormula.products.map((element) => {
          if (element.isFiller) {
            element.type = getIngredient.type;
          }
        });
      }

      const getProductsFormula = productTypes.filter((e) => {
        return filteredFormula.products.find(
          (element) => element.type === e.id
        );
      });

      getProductsFormula.push(getIngredient);
      formulaProducts = getFormulas(getProductsFormula);

      //The variables must have the same number of ingredients, with the exception of fillers.
      //If this number is not the same, it means that the ingredients cannot be linked to the formula. In the case of fillers, one replaces the other.
      if (
        getProductsFormula.length !== formulaProducts.length &&
        !getIngredient?.isFiller
      ) {
        toast.error(
          `The ingredient ${ingredient} cannot be added to ${formula}`,
          { duration: 5000 }
        );
        return;
      }

      suggestedFormulasList = [
        {
          orderFormula: filteredFormula.orderFormula,
          id: filteredFormula.id,
          name: formula,
          products: formulaProducts.map((product) => {
            return {
              ...product,
              ...productTypes.find((element) => element.id === product.type),
              color: product.isFiller ? "#9146fa" : "#49b2f5",
              bankInterestRate: 0,
              demurrage: 0,
              demurrageDuration: 0,
              demurragePrice: 0,
              loanCashoutDuration: 0,
              passageMode: 1,
              placeOfBlending: 1,
              seaFreight: 0,
              storageCost: 0,
              storageDuration: 0,
              transit: "NONE",
              port:
                dropdownSelectedPOE && dropdownSelectedPOE.id
                  ? dropdownSelectedPOE.id
                  : null,
            };
          }),
          totalPrice: "",
        },
        ...formulasList,
      ];
    }

    suggestedFormulasList.sort(function (a, b) {
      if (a.orderFormula > b.orderFormula) {
        return 1;
      }
      if (a.orderFormula < b.orderFormula) {
        return -1;
      }
      return 0;
    });

    setFormulas(suggestedFormulasList);
    toast.success(`The product ${ingredient} was added to ${formula}`, {
      duration: 5000,
    });
  }

  function removeProductFormula(formula, ingredient) {
    const filteredFormula = formulas.find((e) => e.name === formula.name);

    let filteredIngredient = filteredFormula.products.filter(
      (e) => e.type === ingredient
    );

    const removeIngredient = filteredFormula.products.filter((e) => {
      return e.type !== filteredIngredient[0].type;
    });

    const restOfProducts = productTypes.filter((e) => {
      return removeIngredient.find((element) => element.type === e.id);
    });

    let suggestedFormulasList = [];
    const formulasList = formulas.filter((e) => e.name !== formula.name);

    if (props?.match?.params?.formulaId) {
      suggestedFormulasList = [
        {
          id: filteredFormula.id,
          orderFormula: filteredFormula.orderFormula,
          name: formula.name,
          products: [...removeIngredient],
          totalPrice: "",
        },
      ];
    } else {
      const formulaProducts = getFormulas(restOfProducts);

      suggestedFormulasList = [
        {
          orderFormula: formula.orderFormula,
          id: formula.id,
          name: formula.name,
          products: formulaProducts.map((product) => {
            return {
              ...filteredFormula.products.find(
                (element) => element.id === product.type
              ),
              percentage: product.percentage,
            };
          }),
          totalPrice: "",
        },
        ...formulasList,
      ];
    }

    suggestedFormulasList.sort(function (a, b) {
      if (a.orderFormula > b.orderFormula) {
        return 1;
      }
      if (a.orderFormula < b.orderFormula) {
        return -1;
      }
      return 0;
    });

    setFormulas(suggestedFormulasList);

    const nameIngredient =
      productTypes.find((it) => it.id === ingredient)?.name || "INVALID";
    toast.success(
      `The product ${nameIngredient} of ${formula.name} was deleted`,
      { duration: 5000 }
    );
  }

  function getFormulas(filteredNutrients) {
    const input = { N, P2O5: P, K2O: K, Ca, Mg, S, Zn, B, Cu, Cl };

    for (let i = 0; i < filteredNutrients.length; i++) {
      const j = Math.floor(Math.random() * (i + 1));
      [filteredNutrients[i], filteredNutrients[j]] = [
        filteredNutrients[j],
        filteredNutrients[i],
      ];
    }

    const sendNutrient = filteredNutrients.map((rest) => {
      const formatObject = {
        id: rest.id,
        isFiller: rest.isFiller,
      };

      const removeProperty = {};
      for (const key in rest) {
        removeProperty[key] = rest[key];
      }

      delete removeProperty.hs6Code;
      delete removeProperty.maxFob;
      delete removeProperty.minFob;
      delete removeProperty.name;
      delete removeProperty.customTarrif;
      delete removeProperty.isFiller;
      delete removeProperty.id;

      formatObject.composition = removeProperty;

      return formatObject;
    });

    const result = optimizePercentages(input, sendNutrient);

    result.forEach((it) => {
      const index = filteredNutrients.findIndex((p) => p.id === it.typeId);
      const percentage = parseFloat(it.percentage.toFixed(1));
      dispatch(Actions.updateProduct(index, { percentage }));
    });

    const filterResult = result.filter(
      (e) => e.percentage > 0 && e.percentage <= 100
    );

    let percentage = 0;

    let formula = [];

    for (let i = 0; i < filterResult.length; i++) {
      percentage = filterResult[i].percentage + percentage;
      if (percentage >= 100) {
        console.log("percentage > 100", percentage);
        percentage = percentage - filterResult[i].percentage;
        break;
      }
      console.log("percentage", percentage);
      formula.push(filterResult[i]);
    }

    const formatArrayProduct = filterResult.reduce((memo, curr) => {
      memo.push({
        type: curr.typeId,
        percentage: curr.percentage.toFixed(1),
      });
      return memo;
    }, []);

    productTypes.filter((e) => {
      return formatArrayProduct.find((element, index) => {
        if (element.type === e.id && e.isFiller) {
          formatArrayProduct[index] = {
            isFiller: true,
            ...formatArrayProduct[index],
          };
        }
      });
    });

    formatArrayProduct.sort((a, b) => a.type - b.type);

    return formatArrayProduct;
  }

  function handleDragEnd(result) {
    if (!result.destination || result.destination.droppableId === "ingredients")
      return;

    const formulaDropped = formulas.find(
      (e) => e.name === result.destination.droppableId
    );
    const productAlreadyExists = formulaDropped.products.filter((e) => {
      const nameProduct =
        productTypes.find((it) => it.id === e.type)?.name || "INVALID";
      if (nameProduct === result.draggableId) return e;
    });

    if (
      formulaDropped &&
      formulaDropped.name &&
      result.destination.droppableId &&
      result.draggableId
    ) {
      if (productAlreadyExists.length > 0) {
        toast.error(
          `The ${result.destination.droppableId} already contain ${result.draggableId}`,
          { duration: 5000 }
        );
        return;
      } else
        addProductFormula(result.destination.droppableId, result.draggableId);
    }
  }

  function updateFormulas(formulaUpdate) {
    const totalPercentage = formulaUpdate.products.reduce((memo, curr) => {
      memo = parseFloat(curr.percentage) + parseFloat(memo);
      return memo;
    }, 0);

    if (totalPercentage !== 100) {
      toast.error(
        `The sum of the ingredients must complete 100%. Got ${totalPercentage.toFixed(
          1
        )}%`,
        {
          icon: "⚠️",
          duration: 6000,
        }
      );
    } else {
      toast.success("Your formula is perfect!");
    }

    setFormulas(
      [
        ...formulas.filter((f) => f.id !== formulaUpdate.id),
        formulaUpdate,
      ].sort((a, b) => a.orderFormula - b.orderFormula)
    );
  }

  function handleShare() {
    if (props?.match?.params?.formulaId) {
      dispatch(
        Actions.loadFormula(
          formulaRedux.id,
          formulaRedux.name,
          formulaRedux.products,
          formulaRedux.public
        )
      );
      setSharing(true);
    }
  }

  function saveEditProductDialog(data) {
    const manipul = [
      ...formulas.filter((e) => e.name !== formulaRedux.name),
      {
        orderFormula: formulaRedux.orderFormula,
        name: formulaRedux.name,
        products: [...data],
        totalPrice: "",
      },
    ];

    setFormulas(
      manipul.sort(function (a, b) {
        if (a.orderFormula > b.orderFormula) {
          return 1;
        }
        if (a.orderFormula < b.orderFormula) {
          return -1;
        }
        return 0;
      })
    );
  }

  return (
    <>
      <Toaster />
      <div className="all--wrapper desktop">
        <EditProductDialog onSave={saveEditProductDialog} />
        {props?.match?.params?.formulaId && sharing && (
          <ShareFormulaDialog
            id={formulaRedux.id}
            visible={sharing}
            setVisible={setSharing}
          />
        )}
        <div className={Styles.FormulaCreation}>
          <div className={Styles.Workspace}>
            <DragDropContext onDragEnd={handleDragEnd}>
              <aside>
                <ListOfIngredients />
              </aside>
              <div className={Styles.Main}>
                <div className={Styles.ChoosePlaces}>
                  <div className={Styles.Places}>
                    <div className={Styles.PlacesStart}>
                      <img src={AirplaneImg} alt="" />
                    </div>
                    <div className={Styles.PlacesEnd}>
                      <h1>{t("Choose your preferences below")}</h1>
                      <div className={Styles.BlendingPosition}>
                        <div className={Styles.BlendingAndDestination}>
                          <SelectDropdown
                            myLabel={t("Departure City")}
                            inputName={"portOfEntry"}
                            myId={"searchbarPOE"}
                            myPlaceholder={"Select the departure city"}
                            selectOptions={selectListPOE}
                            completeOptions={ports[0]}
                            onSelectValue={handleChangeDropdown}
                            setValue={setDropdownSelectedPOE}
                            open={!dropdownSelectedPOE?.id ? true : false}
                            value={dropdownSelectedPOE}
                          />
                        </div>
                        {/* <div className={Styles.BlendingAndDestination}>
                          <SelectDropdown
                            myLabel={"Place of Processing"}
                            inputName={"placeOfProcessing"}
                            myId={"searchbarPOP"}
                            myPlaceholder={"Select the Place of Processing"}
                            selectOptions={selectListPOP}
                            completeOptions={placesOfBlending[0]}
                            isInfo="Place of Bagging/Blending"
                            onSelectValue={handleChangeDropdown}
                            setValue={setDropdownSelectedPOP}
                            open={
                              dropdownSelectedPOE?.id &&
                              !dropdownSelectedPOP?.id
                                ? true
                                : false
                            }
                            value={dropdownSelectedPOP}
                          />
                        </div> */}
                        <div className={Styles.BlendingAndDestination}>
                          <SelectDropdown
                            myLabel={t("Arrival City")}
                            inputName={"finalDestination"}
                            myId={"searchbarFD"}
                            myPlaceholder={"Select the arrival city"}
                            selectOptions={selectListFD}
                            completeOptions={finalDestinations[(0, 0)]}
                            onSelectValue={handleChangeDropdown}
                            setValue={setDropdownSelectedDestination}
                            open={
                              dropdownSelectedPOE?.id &&
                              dropdownSelectedPOP?.id &&
                              !dropDownSelectedDestination?.id
                                ? true
                                : false
                            }
                            value={dropDownSelectedDestination}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={Styles.NutrientsExplanation}>
                  <div className={Styles.Nutrients}>
                    <img src={Science} alt="" />
                    <div className={Styles.NutrientsRight}>
                      <div className="nutrients--header">
                        <h1 className={Styles.NutrientsTitle}>
                          {t("Your target grade")}
                        </h1>

                        <img
                          src={InfoIcon}
                          onMouseEnter={() => setIsHoveringNutrients(true)}
                          onMouseLeave={() => setIsHoveringNutrients(false)}
                          alt=""
                        />
                        <div
                          className={`NutrientsInfo ${
                            isHoveringNutrients ? "" : "hidden"
                          }`}
                        >
                          <p>{t("The sum of the nutrients must be 100")}</p>
                        </div>
                      </div>
                      <div className={Styles.TargetGrade}>
                        <div className="nutrients--group">
                          <div className="TargetGradeUnit">
                            <div className="TargetGradeUnitStart npk">
                              <p>N</p>
                            </div>
                            <div className="TargetGradeUnitEnd npkEnd">
                              <input
                                type="number"
                                value={N === 0 ? "" : N}
                                onChange={(e) =>
                                  setValuesNutrientsFilter(e, setN)
                                }
                                max="100"
                                min="0"
                              />
                            </div>
                          </div>
                          <div className="TargetGradeUnit">
                            <div className="TargetGradeUnitStart npk">
                              <p>P</p>
                            </div>
                            <div className="TargetGradeUnitEnd npkEnd">
                              <input
                                type="number"
                                value={P === 0 ? "" : P}
                                onChange={(e) =>
                                  setValuesNutrientsFilter(e, setP)
                                }
                                max="100"
                                min="0"
                              />
                            </div>
                          </div>
                          <div className="TargetGradeUnit">
                            <div className="TargetGradeUnitStart npk">
                              <p>K</p>
                            </div>
                            <div className="TargetGradeUnitEnd npkEnd">
                              <input
                                type="number"
                                value={K === 0 ? "" : K}
                                onChange={(e) =>
                                  setValuesNutrientsFilter(e, setK)
                                }
                                max="100"
                                min="0"
                              />
                            </div>
                          </div>
                          <div className="TargetGradeUnit">
                            <div className="TargetGradeUnitStart others">
                              <p>Ca</p>
                            </div>
                            <div className="TargetGradeUnitEnd othersEnd">
                              <input
                                type="number"
                                value={Ca === 0 ? "" : Ca}
                                onChange={(e) =>
                                  setValuesNutrientsFilter(e, setCa)
                                }
                                max="100"
                                min="0"
                              />
                            </div>
                          </div>
                          <div className="TargetGradeUnit">
                            <div className="TargetGradeUnitStart others">
                              <p>Mg</p>
                            </div>
                            <div className="TargetGradeUnitEnd othersEnd">
                              <input
                                type="number"
                                value={Mg === 0 ? "" : Mg}
                                onChange={(e) =>
                                  setValuesNutrientsFilter(e, setMg)
                                }
                                max="100"
                                min="0"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="nutrients--group">
                          <div className="TargetGradeUnit">
                            <div className="TargetGradeUnitStart others">
                              <p>S</p>
                            </div>
                            <div className="TargetGradeUnitEnd othersEnd">
                              <input
                                type="number"
                                value={S === 0 ? "" : S}
                                onChange={(e) =>
                                  setValuesNutrientsFilter(e, setS)
                                }
                                max="100"
                                min="0"
                              />
                            </div>
                          </div>
                          <div className="TargetGradeUnit">
                            <div className="TargetGradeUnitStart others">
                              <p>Zn</p>
                            </div>
                            <div className="TargetGradeUnitEnd othersEnd">
                              <input
                                type="number"
                                value={Zn === 0 ? "" : Zn}
                                onChange={(e) =>
                                  setValuesNutrientsFilter(e, setZn)
                                }
                                max="100"
                                min="0"
                              />
                            </div>
                          </div>
                          <div className="TargetGradeUnit">
                            <div className="TargetGradeUnitStart others">
                              <p>B</p>
                            </div>
                            <div className="TargetGradeUnitEnd othersEnd">
                              <input
                                type="number"
                                value={B === 0 ? "" : B}
                                onChange={(e) =>
                                  setValuesNutrientsFilter(e, setB)
                                }
                                max="100"
                                min="0"
                              />
                            </div>
                          </div>
                          <div className="TargetGradeUnit">
                            <div className="TargetGradeUnitStart others">
                              <p>Cu</p>
                            </div>
                            <div className="TargetGradeUnitEnd othersEnd">
                              <input
                                type="number"
                                value={Cu === 0 ? "" : Cu}
                                onChange={(e) =>
                                  setValuesNutrientsFilter(e, setCu)
                                }
                                max="100"
                                min="0"
                              />
                            </div>
                          </div>
                          <div className="TargetGradeUnit">
                            <div className="TargetGradeUnitStart others">
                              <p>Cl</p>
                            </div>
                            <div className="TargetGradeUnitEnd othersEnd">
                              <input
                                type="number"
                                value={Cl === 0 ? "" : Cl}
                                onChange={(e) =>
                                  setValuesNutrientsFilter(e, setCl)
                                }
                                max="100"
                                min="0"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={Styles.NutrientsBtn}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "11px",
                        }}
                      >
                        <CheckboxAutoAddFiller />
                      </div>
                      <button
                        onClick={() =>
                          props?.match?.params?.formulaId
                            ? toast((t) => (
                                <div>
                                  <p>
                                    <b>Attention! </b>
                                  </p>
                                  <p>
                                    On recalculating you will lose the current
                                    ingredients of the formula!
                                  </p>
                                  <p style={{ marginTop: 7 }}>
                                    Do you want to continue?
                                  </p>
                                  <div
                                    style={{
                                      marginTop: 7,
                                      display: "flex",
                                      justifyContent: "space-evenly",
                                    }}
                                  >
                                    <button
                                      style={{ width: 100 }}
                                      className="btn btn--primary"
                                      onClick={() => {
                                        toast.dismiss(t.id);
                                        checkStart();
                                      }}
                                    >
                                      Yes
                                    </button>
                                    <button
                                      style={{ width: 100 }}
                                      className="btn btn--secondary"
                                      onClick={() => toast.dismiss(t.id)}
                                    >
                                      No
                                    </button>
                                  </div>
                                </div>
                              ))
                            : checkStart()
                        }
                        className={Styles.StartOrRestart}
                      >
                        {buttonStart}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="FormulasBody">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                    }}
                  >
                    <div>
                      <p style={{ margin: "0 0 10px 0" }}>
                        {props?.match?.params?.formulaId
                          ? t("Editing Formula:")
                          : t("Suggested Formulas")}
                      </p>
                    </div>
                    {props?.match?.params?.formulaId && !isLoading && (
                      <div style={{ display: "flex", gap: "10px" }}>
                        {isAdmin && (
                          <Tooltip
                            placement="top"
                            trigger="hover"
                            overlay={`Click to make ${
                              isPublicFormula ? "private" : "public"
                            } and save`}
                          >
                            <button
                              onClick={() =>
                                dispatch(
                                  Actions.updateVisibility(!isPublicFormula)
                                )
                              }
                              className="btn btn--blue center"
                            >
                              {isPublicFormula ? (
                                <img
                                  style={{
                                    maxWidth: "17px",
                                    marginRight: "8px",
                                  }}
                                  src={Unlock}
                                  alt=""
                                />
                              ) : (
                                <img
                                  style={{
                                    maxWidth: "17px",
                                    marginRight: "8px",
                                  }}
                                  src={Lock}
                                  alt=""
                                />
                              )}

                              <p>{`${
                                isPublicFormula ? "Public" : "Private"
                              }`}</p>
                            </button>
                          </Tooltip>
                        )}
                        <button
                          onClick={() => handleShare()}
                          className="btn btn--primary center"
                        >
                          <img
                            style={{ maxWidth: "20px", marginRight: "8px" }}
                            src={ShareIcon}
                            alt=""
                          />
                          <p>Share</p>
                        </button>
                        {/*<button onClick={() => /*window.print() toast(t => 'This is still under development 🚀')} className="btn btnOrangeBg center" >
													<img style={{ "maxWidth": "20px", "marginRight": "8px" }} src={PrintIcon} alt="" />
													<p>Print</p>
												</button>*/}
                      </div>
                    )}
                  </div>
                  <div className={Styles.Formulas}>
                    {isLoading ? (
                      <div className="dfCenter">
                        <ReactLoading
                          type={"spinningBubbles"}
                          color={"#31c5f4"}
                          height={"80px"}
                          width={"80px"}
                        />
                        <h1 style={{ margin: "30px 0" }}>
                          Loading Formulas...
                        </h1>
                      </div>
                    ) : buttonStart != "Iniciar" &&
                      buttonStart != "Start" &&
                      buttonStart != "Commencer" &&
                      formulas.every(
                        (formula) => formula.products.length === 0
                      ) ? (
                      <div className={Styles.HowToUseNPK}>
                        <img src={IdeaImg} alt="How to use the ingredients" />
                        <div className={Styles.HowToUseNPKTexts}>
                          <h1>
                            {t(
                              "Sorry! We didn't find any formulas for these ingredients."
                            )}
                          </h1>
                        </div>
                      </div>
                    ) : (
                      <>
                        {formulas.slice(0, showFormulas).map((e) => {
                          if (e.products.length > 0) {
                            return (
                              <Formula
                                formula={e}
                                setFormulas={setFormulas}
                                discardFormulas={discardFormulas}
                                removeProductFormula={removeProductFormula}
                                productTypes={productTypes}
                                editing={
                                  props?.match?.params?.formulaId ? true : false
                                }
                                setIsResultsOn={setIsResultsOn}
                                setIsListIngredientsOn={setIsListIngredientsOn}
                                dropdownSelectedPOE={dropdownSelectedPOE}
                                dropdownSelectedPOP={dropdownSelectedPOP}
                                dropDownSelectedDestination={
                                  dropDownSelectedDestination
                                }
                                fillers={fillers}
                                updateFormulas={updateFormulas}
                                idFormulaFirebase={
                                  props?.match?.params?.formulaId
                                }
                                /* suggestedFormulas={formulas} */
                              />
                            );
                          }
                        })}
                        {isFormulaShowing == true &&
                          formulas.length > 3 &&
                          showFormulas < formulas.length && (
                            <button
                              onClick={() => setShowFormulas(showFormulas + 3)}
                              className={Styles.ShowMoreBtn}
                            >
                              <p>Show More</p>
                            </button>
                          )}
                        {formulas.length === 0 &&
                          !props.match.params.formulaId && (
                            <div className={Styles.HowToUseNPK}>
                              <img
                                src={IdeaImg}
                                alt="How to use the ingredients"
                              />
                              <div className={Styles.HowToUseNPKTexts}>
                                <h1>
                                  {t(
                                    "How to use the automatic formula generator?"
                                  )}
                                </h1>
                                <p style={{ color: "#04ba56" }}>
                                  1º {t("You need to choose a")}{" "}
                                  <b>
                                    {t("Currency (top right of your screen)")}
                                  </b>
                                </p>
                                <p style={{ color: "#04ba56" }}>
                                  2º {t("Select a")}{" "}
                                  <b>
                                    {t("Departure City and an Arrival City")}
                                  </b>
                                </p>
                                <p style={{ color: "#04ba56" }}>
                                  3º {t("Insert the")}{" "}
                                  <b>{t("Nutrients´s values")}</b>{" "}
                                  {t(
                                    'and then click the "Start" green button!'
                                  )}
                                </p>
                                <p>
                                  {t(
                                    "After that, the system will automatically create the best formulas combinations based on your decisions!"
                                  )}
                                </p>
                              </div>
                            </div>
                          )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </DragDropContext>
          </div>
        </div>
      </div>
      <div className="all--wrapper mobile creationPage">
        <EditProductDialog />
        <DragDropContext onDragEnd={handleDragEnd}>
          <header
            style={{
              display: "flex",
              gap: "8px",
              height: "52px",
              marginBottom: "10px",
            }}
          >
            <button
              onClick={() => setIsPlacesClicked(!isPlacesClicked)}
              className="btn btn--primary"
            >
              Select Places
            </button>
            <button
              onClick={() => setIsNutrientsClicked(!isNutrientsClicked)}
              className="btn btnOrangeBg center w100"
              style={{ maxWidth: "100%" }}
            >
              Nutrients
            </button>
          </header>
          {isPlacesClicked && (
            <div className="modal">
              <div className="modalPlaces">
                <div className="BlendingPosition">
                  <div className="PlacesStart">
                    <img src={AirplaneImg} alt="" />
                  </div>
                  <div className="BlendingAndDestination">
                    <SelectDropdownMobile
                      myLabel={t("Departure City")}
                      inputName={"portOfEntry"}
                      myId={"searchbarPOEMobile"}
                      myPlaceholder={"Select the departure city"}
                      selectOptions={selectListPOE}
                      completeOptions={ports[0]}
                      onSelectValue={handleChangeDropdownMobile}
                      setValue={setDropdownSelectedPOE}
                      value={dropdownSelectedPOE}
                    />
                  </div>
                  {/* <div className="BlendingAndDestination">
                    <SelectDropdownMobile
                      myLabel={"Place of Bagging or Blending"}
                      inputName={"placeOfProcessing"}
                      myId={"searchbarPOPMobile"}
                      myPlaceholder={"Select place of Processing"}
                      selectOptions={selectListPOP}
                      completeOptions={placesOfBlending[0]}
                      isInfo="Place of Processing"
                      onSelectValue={handleChangeDropdownMobile}
                      setValue={setDropdownSelectedPOP}
                      value={dropdownSelectedPOP}
                    />
                  </div> */}
                  <div className="BlendingAndDestination">
                    <SelectDropdownMobile
                      myLabel={t("Arrival City")}
                      inputName={"finalDestination"}
                      myId={"searchbarFDMobile"}
                      myPlaceholder={"Select the arrival city"}
                      selectOptions={selectListFD}
                      completeOptions={finalDestinations[(0, 0)]}
                      onSelectValue={handleChangeDropdownMobile}
                      setValue={setDropdownSelectedDestination}
                      value={dropDownSelectedDestination}
                    />
                  </div>
                </div>
                <div className="div" style={{ display: "flex", gap: "8px" }}>
                  <button
                    className="btn btn--secondary"
                    onClick={() => setIsPlacesClicked(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn--primary"
                    onClick={() => setIsPlacesClicked(false)}
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          )}

          {isResultsOn === true ? (
            <div className="modal">
              <div className="modal--costBreakdown">
                <button
                  className="modalBtn"
                  onClick={() => setIsResultsOn(false)}
                >
                  <img src={XIcon} alt="" />
                </button>
                <CostBreakdown />
              </div>
            </div>
          ) : null}

          {isListIngredientOn === true ? (
            <div className="modal">
              <div className="modalWrapper">
                <div className="filteringOptions">
                  <div className="filteringOptionsInside">
                    <div className="qntdSelectedCards">
                      <h1>Select Ingredients</h1>
                      {/* {isCardsEnabled ?
												<div>
													<p>{qntd} selected</p>
												</div>
												: null} */}
                    </div>
                    <p style={{ fontSize: "14px", color: "#444" }}>
                      Select the ingredients you want to insert by clicking on
                      it. Then click the "Add" button to add it to the formula{" "}
                    </p>
                  </div>
                  <div className="filteringOptionsInside">
                    <div className="ingredientsWrapper">
                      <ListOfIngredients />
                    </div>
                  </div>
                  <div className="filteringOptionsInside">
                    <div className="div">
                      <button
                        className="btn btn--secondary"
                        onClick={() => setIsListIngredientsOn(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn btn--primary" /* onClick={() => compareFormulas()} */
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {isNutrientsClicked ? (
            <div className="modal">
              <div className={Styles.NutrientsExplanation}>
                <div className={Styles.Nutrients}>
                  {/* <img src={Science} alt="" /> */}
                  <div className={Styles.NutrientsRight}>
                    <div
                      className="nutrients--header"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div style={{ display: "flex" }}>
                        <h1 className={Styles.NutrientsTitle}>
                          {t("Your target grade")}
                        </h1>

                        <img
                          src={InfoIcon}
                          onMouseEnter={() => setIsHoveringNutrients(true)}
                          onMouseLeave={() => setIsHoveringNutrients(false)}
                          alt=""
                        />
                      </div>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "11px",
                        }}
                      >
                        <CheckboxAutoAddFiller />
                      </div>
                      <div
                        className={`NutrientsInfo ${
                          isHoveringNutrients ? "" : "hidden"
                        }`}
                      >
                        <p>{t("The sum of the nutrients must be 100")}</p>
                      </div>
                    </div>

                    <div className={Styles.TargetGrade}>
                      <div className="nutrients--group">
                        <div className="TargetGradeUnit">
                          <div className="TargetGradeUnitStart npk">
                            <p>N</p>
                          </div>
                          <div className="TargetGradeUnitEnd npkEnd">
                            <input
                              type="number"
                              value={N === 0 ? "" : N}
                              onChange={(e) =>
                                setValuesNutrientsFilter(e, setN)
                              }
                              max="100"
                              min="0"
                            />
                          </div>
                        </div>
                        <div className="TargetGradeUnit">
                          <div className="TargetGradeUnitStart npk">
                            <p>P</p>
                          </div>
                          <div className="TargetGradeUnitEnd npkEnd">
                            <input
                              type="number"
                              value={P === 0 ? "" : P}
                              onChange={(e) =>
                                setValuesNutrientsFilter(e, setP)
                              }
                              max="100"
                              min="0"
                            />
                          </div>
                        </div>
                        <div className="TargetGradeUnit">
                          <div className="TargetGradeUnitStart npk">
                            <p>K</p>
                          </div>
                          <div className="TargetGradeUnitEnd npkEnd">
                            <input
                              type="number"
                              value={K === 0 ? "" : K}
                              onChange={(e) =>
                                setValuesNutrientsFilter(e, setK)
                              }
                              max="100"
                              min="0"
                            />
                          </div>
                        </div>
                        <div className="TargetGradeUnit">
                          <div className="TargetGradeUnitStart others">
                            <p>Ca</p>
                          </div>
                          <div className="TargetGradeUnitEnd othersEnd">
                            <input
                              type="number"
                              value={Ca === 0 ? "" : Ca}
                              onChange={(e) =>
                                setValuesNutrientsFilter(e, setCa)
                              }
                              max="100"
                              min="0"
                            />
                          </div>
                        </div>
                        <div className="TargetGradeUnit">
                          <div className="TargetGradeUnitStart others">
                            <p>Mg</p>
                          </div>
                          <div className="TargetGradeUnitEnd othersEnd">
                            <input
                              type="number"
                              value={Mg === 0 ? "" : Mg}
                              onChange={(e) =>
                                setValuesNutrientsFilter(e, setMg)
                              }
                              max="100"
                              min="0"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="nutrients--group">
                        <div className="TargetGradeUnit">
                          <div className="TargetGradeUnitStart others">
                            <p>S</p>
                          </div>
                          <div className="TargetGradeUnitEnd othersEnd">
                            <input
                              type="number"
                              value={S === 0 ? "" : S}
                              onChange={(e) =>
                                setValuesNutrientsFilter(e, setS)
                              }
                              max="100"
                              min="0"
                            />
                          </div>
                        </div>
                        <div className="TargetGradeUnit">
                          <div className="TargetGradeUnitStart others">
                            <p>Zn</p>
                          </div>
                          <div className="TargetGradeUnitEnd othersEnd">
                            <input
                              type="number"
                              value={Zn === 0 ? "" : Zn}
                              onChange={(e) =>
                                setValuesNutrientsFilter(e, setZn)
                              }
                              max="100"
                              min="0"
                            />
                          </div>
                        </div>
                        <div className="TargetGradeUnit">
                          <div className="TargetGradeUnitStart others">
                            <p>B</p>
                          </div>
                          <div className="TargetGradeUnitEnd othersEnd">
                            <input
                              type="number"
                              value={B === 0 ? "" : B}
                              onChange={(e) =>
                                setValuesNutrientsFilter(e, setB)
                              }
                              max="100"
                              min="0"
                            />
                          </div>
                        </div>
                        <div className="TargetGradeUnit">
                          <div className="TargetGradeUnitStart others">
                            <p>Cu</p>
                          </div>
                          <div className="TargetGradeUnitEnd othersEnd">
                            <input
                              type="number"
                              value={Cu === 0 ? "" : Cu}
                              onChange={(e) =>
                                setValuesNutrientsFilter(e, setCu)
                              }
                              max="100"
                              min="0"
                            />
                          </div>
                        </div>
                        <div className="TargetGradeUnit">
                          <div className="TargetGradeUnitStart others">
                            <p>Cl</p>
                          </div>
                          <div className="TargetGradeUnitEnd othersEnd">
                            <input
                              type="number"
                              value={Cl === 0 ? "" : Cl}
                              onChange={(e) =>
                                setValuesNutrientsFilter(e, setCl)
                              }
                              max="100"
                              min="0"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ display: "flex", gap: "8px", marginTop: "20px" }}
                  >
                    <button
                      onClick={() => setIsNutrientsClicked(false)}
                      className="btn btn--secondary"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => checkStart()}
                      className="btn btn--primary"
                    >
                      {buttonStart}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div className="formulas--wrapper">
            <h1 className={Styles.NutrientsTitle}>
              {props?.match?.params?.formulaId
                ? t("Editing Formula:")
                : t("Suggested Formulas")}
            </h1>
            <div className={Styles.Formulas}>
              {isLoading ? (
                <div className="dfCenter">
                  <ReactLoading
                    type={"spinningBubbles"}
                    color={"#31c5f4"}
                    height={"80px"}
                    width={"80px"}
                  />
                  <h1 style={{ margin: "30px 0" }}>Loading Formulas...</h1>
                </div>
              ) : buttonStart != "Iniciar" &&
                buttonStart != "Start" &&
                buttonStart != "Commencer" &&
                formulas.every((formula) => formula.products.length === 0) ? (
                <div className={Styles.HowToUseNPK}>
                  <img src={IdeaImg} alt="How to use the ingredients" />
                  <div className={Styles.HowToUseNPKTexts}>
                    <h1>
                      {t(
                        "Sorry! We didn't find any formulas for these ingredients."
                      )}
                    </h1>
                  </div>
                </div>
              ) : (
                <>
                  {formulas.slice(0, showFormulas).map((e) => {
                    if (e.products.length > 0) {
                      return (
                        <Formula
                          formula={e}
                          discardFormulas={discardFormulas}
                          removeProductFormula={removeProductFormula}
                          productTypes={productTypes}
                          editing={props.match.params.formulaId ? true : false}
                          setIsResultsOn={setIsResultsOn}
                          setIsListIngredientsOn={setIsListIngredientsOn}
                          dropdownSelectedPOE={dropdownSelectedPOE}
                          dropdownSelectedPOP={dropdownSelectedPOP}
                          dropDownSelectedDestination={
                            dropDownSelectedDestination
                          }
                          fillers={fillers}
                          setFormulas={setFormulas}
                          idFormulaFirebase={props?.match?.params?.formulaId}
                          isResultsOn={isResultsOn}
                          /* suggestedFormulas={formulas} */
                        />
                      );
                    }
                  })}
                  {isFormulaShowing == true &&
                    formulas.length > 3 &&
                    showFormulas < formulas.length && (
                      <button
                        onClick={() => setShowFormulas(showFormulas + 3)}
                        className={Styles.ShowMoreBtn}
                      >
                        <p>Show More</p>
                      </button>
                    )}
                  {formulas.length === 0 && (
                    <div className={Styles.HowToUseNPK}>
                      <img src={IdeaImg} alt="How to use the ingredients" />
                      <div className={Styles.HowToUseNPKTexts}>
                        <h1>
                          {t("How to use the automatic formula generator?")}
                        </h1>
                        <p>
                          Click the "select places" button to select the places
                          your formula will travel.
                        </p>
                        <p>
                          Click in the "Nutrients" button to add the nutrients
                          for your formula and then click the "Start" button to
                          generate the formula.
                        </p>
                        <p>
                          You also need to select the currency. In default, the
                          currency will be FCFA, but if you want to change it,
                          just click in the select box in the top right corner.
                        </p>

                        <p>
                          After that, the system will automatically create the
                          best formulas combinations based on your decisions!
                        </p>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </DragDropContext>
      </div>
      <div className="print" id="printable"></div>
    </>
  );
}
