import React, { useContext, useEffect, useState } from "react";
import { ApiContext } from "../../../../providers/ApiProvider";
import { NotificationContext } from "../../../../providers/NotificationProvider";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import CommonStyles from "../../../CommonStyles.module.scss";
import { Dialog } from "primereact/dialog";
import GenericMaintenanceScreen from "./GenericMaintanceScreen";
import FloatLabel from "../../../utils/FloatLabel";
import CountrySelector from "../../../utils/selectors/CountrySelector";
import useErrorHandler from "../../../../hooks/useErrorHandler";
import { Checkbox } from "primereact/checkbox";
import toast from "react-hot-toast";
import ReactLoading from "react-loading";
import XIcon from "../../../../assets/images/XIcon.svg";
import { t } from "i18next";

const EditDialog = ({ id, onHide, visible, notify }) => {
  const api = useContext(ApiContext);
  const errorHandler = useErrorHandler(
    t("Something went wrong on managing the data")
  );

  const [loading, setLoading] = useState(false);
  const [triedOnce, setTriedOnce] = useState(false);
  const [name, setName] = useState("");
  const [country, setCountry] = useState();
  const [isPlaceOfBlending, setPlaceOfBlending] = useState(false);

  useEffect(() => {
    refresh();
  }, [id]);

  const refresh = () => {
    setTriedOnce(false);
    setLoading(true);
    if (id) {
      api
        .getTown(id)
        .then(({ data }) => {
          setName(data.name);
          setCountry(data.country.alphaCode);
          setPlaceOfBlending(data.isPlaceOfBlending);
          setLoading(false);
        })
        .catch(errorHandler);
    } else {
      resetForm();
    }
  };

  const resetForm = () => {
    setName("");
    setCountry(undefined);
    setLoading(false);
    setPlaceOfBlending(false);
  };

  const finish = () => {
    toast.success(t("Town saved successfully"), name);
    onHide();
    notify();
  };

  const send = () => {
    setTriedOnce(true);
    if (name && country) {
      const payload = { name, country, isPlaceOfBlending };
      setLoading(true);
      const action = id ? api.editTown(id, payload) : api.addTown(payload);
      action.then(finish).catch(errorHandler);
    }
  };

  const deletedToast = () => {
    toast.success(t("Country deleted successfully"), name);
    onHide();
    notify();
  };

  const handleDelete = () => {
    api.deleteTown(id).then(deletedToast).catch(errorHandler);
  };

  const askDelete = () => {
    toast((_t) => (
      <div className="deleteToastAdm">
        <h1>
          {t("Are you sure you want to delete")}{" "}
          <b>
            {name} ({country})
          </b>
          ?
        </h1>
        <p>{t("This is a one-way action!")}</p>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button
            className="btn btn--secondary"
            onClick={() => toast.dismiss(_t.id)}
          >
            {t("Cancel")}
          </button>
          <button
            style={{ marginLeft: "5px" }}
            className="btn btn--alert center"
            onClick={handleDelete}
          >
            {t("Delete")}
          </button>
        </div>
      </div>
    ));
  };

  const header = (
    <div style={{ position: "relative" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h1 style={{ margin: "0" }}>
          {id ? `${t("Editing")} ${name} (${country})` : t("Creating")}
        </h1>
        <button className="xIconButton center" onClick={onHide}>
          <img src={XIcon} alt="" />
        </button>
      </div>
      <p>
        {id
          ? "Change the name, the country and define if is a place of blending or not"
          : "Write the name, select the country and define if is a place of blending or not to create a new one"}
      </p>
    </div>
  );

  const body = (
    <>
      <div className="firstFloatLabel">
        <FloatLabel id="name" label="Name">
          <InputText
            value={name}
            onChange={({ target }) => setName(target.value)}
          />
        </FloatLabel>
      </div>
      {triedOnce && !name && (
        <span className={CommonStyles.RedText}>*please provide a name</span>
      )}
      <div
        style={{
          margin: "8px 0 10px 0",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <label className="selectLabelAdm" htmlFor="country">
          Country
        </label>
        <CountrySelector id="country" value={country} onChange={setCountry} />
      </div>
      <div>
        <Checkbox
          id="isPlaceOfBlending"
          checked={isPlaceOfBlending}
          onChange={(e) => setPlaceOfBlending(e.checked)}
        />
        <label htmlFor="isPlaceOfBlending">{t("Is Place of Blending")}</label>
      </div>
    </>
  );

  const footer = (
    <div style={{ width: "100%", display: "flex" }}>
      {id && !loading ? (
        <>
          <button className="btn btn--alert center" onClick={askDelete}>
            Delete
          </button>
          <button className="btn btn--primary" onClick={send}>
            Save
          </button>
        </>
      ) : (
        !loading && (
          <button className="btn btn--primary" onClick={send}>
            {t("Create")}
          </button>
        )
      )}
    </div>
  );

  return (
    <Dialog
      className="modalAdmWrapper"
      onHide={onHide}
      visible={visible}
      header={header}
      footer={footer}
      draggable={false}
    >
      {loading ? (
        <div className="dfCenter">
          <ReactLoading
            type={"spinningBubbles"}
            color={"#31c5f4"}
            height={"40px"}
            width={"40px"}
          />
        </div>
      ) : (
        body
      )}
    </Dialog>
  );
};

const TownMaintenance = () => {
  const api = useContext(ApiContext);

  const dialog = ({ id, onHide, visible, notify }) => (
    <EditDialog id={id} onHide={onHide} visible={visible} notify={notify} />
  );

  const filterData = (data, filterTable) => {
    if (filterTable.length === 0) return data;

    return data.filter(
      (e) =>
        (e.name &&
          e.name
            .toLowerCase()
            .replace(/[\s-]/g, "")
            .includes(filterTable.replace(/[\s-]/g, "").toLowerCase())) ||
        (e.country &&
          e.country.displayName
            .toLowerCase()
            .replace(/[\s-]/g, "")
            .includes(filterTable.replace(/[\s-]/g, "").toLowerCase())) ||
        (e.country &&
          e.country.alphaCode
            .toLowerCase()
            .replace(/[\s-]/g, "")
            .includes(filterTable.replace(/[\s-]/g, "").toLowerCase()))
    );
  };

  return (
    <GenericMaintenanceScreen
      dataProvider={api.getTowns}
      renderEditDialog={dialog}
      filterData={filterData}
    >
      <Column field="id" header="Id" sortable />
      <Column field="name" header={t("Name")} sortable />
      <Column
        field="isPlaceOfBlending"
        header={t("Place of Blending")}
        body={({ isPlaceOfBlending }) => (isPlaceOfBlending ? "Yes" : "No")}
        sortable
      />
      <Column
        field="country.displayName"
        header={t("Country")}
        body={({ country }) => `${country.displayName} (${country.alphaCode})`}
        sortable
      />
    </GenericMaintenanceScreen>
  );
};

export default TownMaintenance;
